enum CountriesEnum {
  PER = 'PER',
  CHL = 'CHL',
  ARG = 'ARG',
  MEX = 'MEX',
  COL = 'COL',
  ECU = 'ECU',
}

export { CountriesEnum };

export interface Countries {
  countryCode: string;
  countryName: string;
  internationalCallingCode: number;
  isEnabled: boolean;
}
