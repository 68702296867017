import { useTranslation } from 'react-i18next';
import Table from '@/components/common/tables/Table';
import styles from './AddressVerification.module.scss';
import TickIcon from '@/components/icons/TickIcon';
import ExternalLinkIcon from '@/components/icons/ExternalLinkIcon';
import Typography from '@/components/common/typography/Typography';
import { useKycApplications } from '@/utils/hooks/useKycApplications';
import { CustomerDocStatus, Permissions } from '@/types';
import LinkButton from '@/components/common/button/LinkButton';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import { lightWhite } from '@/utils/colors';
import WidgetBox from '@/components/customer/widget-box/WidgetBox';
import TimestampLabel from '@/components/common/timestamp-label/TimestampLabel';
import SomethingWentWrong from '@/components/common/something-went-wrong/SomethingWentWrong';
export interface AddressVerificationProps {
  customerId: string;
  urlRoot: string;
}

export default function AddressVerification({
  customerId,
  urlRoot,
}: Readonly<AddressVerificationProps>): React.JSX.Element {
  const { t } = useTranslation('customerOverview');
  const { kycInfo, isError } = useKycApplications(customerId);
  const { documents: { address } = {} } = kycInfo;

  const hasAddressDocs = address?.state === CustomerDocStatus.APPROVED && address?.id;
  const addressEdvApprovedDate = kycInfo?.addressEdvApprovedDate;
  const geolocationApproved = kycInfo?.geolocationApprovedDate;

  return (
    <WidgetBox title={t('widgets.address-verification.header-title')}>
      {isError && <SomethingWentWrong />}
      {!isError && (
        <Table colsTemplate='1fr 3.5rem 1fr'>
          <Table.Head>
            <div></div>
          </Table.Head>
          <Table.Head>
            <Typography variant='p2Medium'>
              {/* t('widgets.address-verification.timestamp-of-completion') */}
            </Typography>
          </Table.Head>
          <Table.Head>
            <div></div>
          </Table.Head>
          <Table.Row>
            <Table.Cell>Match via Databroker</Table.Cell>
            <Table.Cell>{addressEdvApprovedDate && <TickIcon />}</Table.Cell>
            <Table.Cell>
              <TimestampLabel timestamp={addressEdvApprovedDate} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Geolocation</Table.Cell>
            <Table.Cell>{geolocationApproved && <TickIcon />}</Table.Cell>
            <Table.Cell>
              <TimestampLabel timestamp={geolocationApproved} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Address verification</Table.Cell>
            <Table.Cell>{kycInfo?.addressDocApprovedDate && <TickIcon />}</Table.Cell>
            <Table.Cell>
              <div className={styles.verification}>
                <UserPermissionsGuard permission={Permissions.kycriskDocsView}>
                  {hasAddressDocs ? (
                    <LinkButton
                      href={
                        urlRoot + `/customer/${customerId}/documents/address-docs/${kycInfo?.documents?.address?.id}`
                      }
                      target='_blank'
                    >
                      <ExternalLinkIcon />
                    </LinkButton>
                  ) : (
                    <div className={styles['flex-box']}>
                      <ExternalLinkIcon color={lightWhite.cssColor} />
                    </div>
                  )}
                </UserPermissionsGuard>
              </div>
            </Table.Cell>
          </Table.Row>
        </Table>
      )}
    </WidgetBox>
  );
}
