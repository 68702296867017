export enum CustomerDocStatus {
  APPROVED = 'Approved',
  REVIEW = 'Review',
  REJECTED = 'Rejected',
}

export interface CustomerAddressDoc {
  addressDocId: string;
  status: CustomerDocStatus;
  file: string;
  fileType: string;
}

export interface CustomerIdentityDoc {
  docType: string;
  identityDocId: string;
  docExpirationDate?: string;
  docIssueDate?: string;
  frontImageId?: string;
  backImageId?: string;
  selfieImageId?: string;
  docStatus?: CustomerDocStatus;
  createdOn?: string;
}

export interface CustomerAcceptedDoc {
  acceptedDocType: string;
  acceptedDocTime: Date;
}

export interface FiscalNumberType {
  fiscalDataType: string;
}

export interface AssociatedAffiliate {
  affiliateId: string;
  bannerId: string;
  siteId: string;
}

export interface Customer {
  customerId?: string;
  phoneNumber?: string;
  countryCode?: string;
  firstName?: string;
  lastName?: string;
  status?: CustomerStatusEnum;
  email?: string;
  birthDate?: Date | string;
  birthCountry?: string;
  birthCity?: string;
  salaryRange?: string;
  sourceOfWealth?: string;
  sourceOfFund?: string;
  declaredPep?: boolean;
  officialPep?: boolean;
  profileId?: number;
  addressLine1?: string;
  addressLine2?: string;
  addressPostalCode?: string;
  addressCountryCode?: string;
  addressCity?: string;
  addressState?: string;
  signUpTime?: Date | string;
  accountCreationTime?: Date | string;
  promoActivitiesEmail?: boolean;
  secondNationality?: string;
  fiscalNumber?: string;
  fiscalNumberType?: FiscalNumberType;
  isSanctionResult?: boolean;
  acceptedDocs?: CustomerAcceptedDoc[];
  identityDocs?: CustomerIdentityDoc[];
  associatedAffiliate?: AssociatedAffiliate;
  amlRating?: string;
}

export enum CustomerStatusEnum {
  ACTIVATED = 'Activated',
  PENDING_ONBOARDING = 'Pending Onboarding',
  UNDER_VERIFICATION = 'User Under Verification',
  SUSPENDED = 'Blocked',
  VERIFIED = 'User Verified',
  ONBOARDED = 'Onboarded',
  CLOSED = 'Closed',
}
