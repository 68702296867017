import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import { type PaymentMethod } from '@/types/PaymentMethod';
import { type FormModalMode } from '@/utils/interfaces';

import { getPaymentMethodTopupByGroup, createPaymentMethodTopup, updatePaymentMethodTopup } from './actions';

interface ModalState {
  mode: FormModalMode;
  data: PaymentMethod;
  success: boolean;
  loading: boolean;
}

interface PaymentMethodTopupState {
  paymentMethods: PaymentMethod[];
  loading: boolean;
  success: boolean;
  modal: ModalState;
}

export const emptyMethod: PaymentMethod = {
  name: '',
  nameTranslationKey: '',
  groupId: undefined,
  countryCode: '',
  isEnabled: false,
  imageUrl: '',
  channelId: '',
  description: '',
  descriptionTranslationKey: '',
  order: 0,
  minAmount: 0,
  minAmountCurrency: '',
  reason: '',
  allowInCheckout: false,
};

const initialModalState: ModalState = {
  mode: 'closed',
  data: emptyMethod,
  success: false,
  loading: false,
};

const initialState: PaymentMethodTopupState = {
  paymentMethods: [],
  loading: false,
  success: false,
  // Stores modal state and relevant modal data
  modal: initialModalState,
};

export const paymentMethodTopupSlice = createSlice({
  name: 'paymentMethodTopup',
  initialState,
  reducers: {
    setModalData(
      state,
      action: {
        payload: PaymentMethod;
      },
    ) {
      state.modal.data = action.payload;
    },
    openModal(
      state,
      action: {
        payload: {
          mode: Exclude<FormModalMode, 'close'>;
          data?: PaymentMethod;
        };
      },
    ) {
      state.modal.mode = action.payload.mode;
      if (action.payload.data) {
        state.modal.data = action.payload.data;
      }
    },
    // Use in case we need to just close the modal and keep modal values
    closeModal(state) {
      state.modal.mode = 'closed';
    },
    closeAndResetModal(state) {
      state.modal = initialModalState;
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(getPaymentMethodTopupByGroup.pending, state => {
      state.loading = true;
    });
    builder.addCase(getPaymentMethodTopupByGroup.fulfilled, (state, action: PayloadAction<PaymentMethod[]>) => {
      // Temporary solution until https://payretailers.atlassian.net/browse/DIG-13533 is done
      const tempararySanitiseImageUrl = (iconField: string): string => {
        const regex = /^https?:\/\/[^/]+\/?/;
        return iconField.replace(regex, '');
      };

      const paymentMethods = action.payload.map(({ imageUrl, ...method }) => {
        return {
          ...method,
          imageUrl: tempararySanitiseImageUrl(imageUrl),
        };
      });

      state.paymentMethods = paymentMethods;
      state.loading = false;
    });
    builder.addCase(getPaymentMethodTopupByGroup.rejected, state => {
      state.loading = false;
      state.success = false;
    });
    // Create Payment Method
    builder.addCase(createPaymentMethodTopup.pending, state => {
      state.modal.loading = true;
      state.modal.success = false;
    });
    builder.addCase(createPaymentMethodTopup.fulfilled, state => {
      state.modal = {
        ...initialModalState,
        success: true,
      };
    });
    builder.addCase(createPaymentMethodTopup.rejected, state => {
      state.modal.loading = false;
      state.modal.success = false;
    });
    // Update Payment Method Group
    builder.addCase(updatePaymentMethodTopup.pending, state => {
      state.modal.loading = true;
      state.modal.success = false;
    });
    builder.addCase(updatePaymentMethodTopup.fulfilled, state => {
      state.modal = {
        ...initialModalState,
        success: true,
      };
    });
    builder.addCase(updatePaymentMethodTopup.rejected, state => {
      state.modal.loading = false;
      state.modal.success = false;
    });
  },
});

export const { setModalData, openModal, closeAndResetModal, reset } = paymentMethodTopupSlice.actions;
