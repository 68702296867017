import { useEffect, useCallback, type FC } from 'react';

import { Select } from 'k8-web-lib-tmp';

import { useAppDispatch, useAppSelector } from '@/lib/redux';
import { searchChannels } from '@/lib/redux/slices/channels-search/actions';
import { setSelectedChannel, reset } from '@/lib/redux/slices/channels-search/slice';
import { type Option } from '@/utils/interfaces';

interface ChannelSelectProps {
  onChange: (value: string) => void;
  disabled?: boolean;
}

const ChannelSelect: FC<ChannelSelectProps> = ({ onChange, disabled = false }) => {
  const dispatch = useAppDispatch();
  const { loading, channelsOptions, selectedChannel } = useAppSelector(state => state.channelsSearch);

  useEffect(() => {
    void dispatch(
      searchChannels({
        transactionType: 'Topup',
        limit: 5,
      }),
    );

    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const onSelect = useCallback(
    (option: Option) => {
      dispatch(setSelectedChannel(option));
      onChange(option.value ?? '');
    },
    [dispatch, onChange],
  );

  const onSearch = useCallback(
    (value: string): void => {
      void dispatch(
        searchChannels({
          transactionType: 'Topup',
          name: value,
          limit: 5,
        }),
      );
    },
    [dispatch],
  );

  return (
    <Select
      options={channelsOptions}
      selected={selectedChannel}
      onSelect={onSelect}
      onSearch={onSearch}
      loading={loading}
      disabled={disabled}
    />
  );
};

export default ChannelSelect;
