import { useTranslation } from 'react-i18next';
import CustomerPiiField from './CustomerPiiField';

export interface AddressDataProps {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  country?: string;
  postalCode?: string;
  state?: string;
}

export function AddressData({
  addressLine1,
  addressLine2,
  city,
  country,
  postalCode,
  state,
}: Readonly<AddressDataProps>): React.JSX.Element {
  const { t } = useTranslation('customerOverview');

  return (
    <div>
      <CustomerPiiField fieldName={t('widgets.customer-pii.address-data.address')}>{addressLine1}</CustomerPiiField>
      {addressLine2 !== undefined && addressLine2.trim() !== '' && <CustomerPiiField>{addressLine2}</CustomerPiiField>}
      <CustomerPiiField fieldName={t('widgets.customer-pii.address-data.city')}>{city}</CustomerPiiField>
      <CustomerPiiField fieldName={t('widgets.customer-pii.address-data.country')}>{country}</CustomerPiiField>
      {state !== undefined && <CustomerPiiField fieldName={'State'}>{state}</CustomerPiiField>}
      <CustomerPiiField fieldName={t('widgets.customer-pii.address-data.postal-code')}>{postalCode}</CustomerPiiField>
    </div>
  );
}
