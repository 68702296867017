import { useMemo } from 'react';
import { PhoneNumberUtil } from 'google-libphonenumber';
import countries from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';
import CustomerPiiField from './CustomerPiiField';
import styles from './ContactInfoData.module.scss';

interface ContactInfoDataProps {
  phoneNumber?: string;
  email?: string;
  countryCode?: string;
}

const phoneUtil = PhoneNumberUtil.getInstance();

export function ContactInfoData(props: Readonly<ContactInfoDataProps>): React.JSX.Element {
  const { phoneNumber, email, countryCode } = props;

  const { t } = useTranslation('customerOverview');

  const { code, phone } = useMemo(() => {
    const alpha2Code = countries.alpha3ToAlpha2(countryCode ?? '');
    const code = `${phoneUtil.getCountryCodeForRegion(alpha2Code ?? '')}`;
    const phone = phoneNumber?.substring(code.length);
    return { code, phone };
  }, [countryCode, phoneNumber]);

  return (
    <div>
      <CustomerPiiField fieldName={t('widgets.customer-pii.contact-info-data.phone-number')}>
        <span className={styles.prefix}>+{code}</span>
        <span>{phone}</span>
      </CustomerPiiField>
      <CustomerPiiField fieldName={t('widgets.customer-pii.contact-info-data.email')}>{email}</CustomerPiiField>
    </div>
  );
}
