import { useEffect } from 'react';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import { useParams } from 'react-router-dom';
import TransactionView from '@/components/customer/transaction-history/transaction-details/TransactionView';
import { transactionTransfers } from '@/lib/redux/slices/transaction-transfer/action';
import { type TransactionWithSlipId } from '@/components/customer/transaction-history/TransactionHistoryDetailWidget';
import Spinner from '@/components/common/spinners/Spinner';
import TransactionStatusStamp from '@/components/common/transaction-status-stamp/TransactionStatusStamp';
import { toUtcDateString } from '@/utils/dateTimeUtils';
import { getTransactionAmountLabel, getTransactionFees, getTransactionTypeLabel } from '@/utils/transactions';

const Transfer = ({ transaction }: { transaction: TransactionWithSlipId }): JSX.Element => {
  const dispatch = useAppDispatch();

  const { transfers, isLoading } = useAppSelector((state: RootState) => state.transactionTransfersSlice);

  const { customerId } = useParams<{ customerId: string }>();

  useEffect(() => {
    void dispatch(transactionTransfers({ transactionId: transaction.slipId }));
  }, [dispatch, transaction]);

  const showRecipient = customerId === transaction.source?.customerId;

  let link;
  if (showRecipient) {
    if (transaction.destination?.customerId) {
      link = `/customer/${transaction.destination?.customerId}/transaction-history?transactionId=${transaction.transactionId}`;
    }
  } else if (transaction.source?.customerId) {
    link = `/customer/${transaction.source?.customerId}/transaction-history?transactionId=${transaction.transactionId}`;
  }

  const columnOne = [
    { label: 'transactionType', value: getTransactionTypeLabel(transaction, customerId), highlight: true },
    { label: 'status', value: <TransactionStatusStamp status={transaction.status} /> },
    {
      label: 'source-feeTotalAmount',
      value:
        transaction.source?.balanceAmountCurrency &&
        `${transaction.source.balanceAmountCurrency} ${getTransactionFees(transaction.source)}`,
    },
  ];

  const columnTwo = [
    { label: 'amount', value: getTransactionAmountLabel(transaction, { customerId }) },
    ...(showRecipient
      ? [
          {
            label: 'recipientName',
            value: transfers?.recipient && `${transfers?.recipient.firstName} ${transfers?.recipient.lastName}`,
            link,
          },
        ]
      : [
          {
            label: 'senderName',
            value: transfers?.sender && `${transfers?.sender.firstName} ${transfers?.sender.lastName}`,
            link,
          },
        ]),
    {
      label: 'destination-feeTotalAmount',
      value:
        transaction.destination?.balanceAmountCurrency &&
        `${transaction.destination.balanceAmountCurrency} ${getTransactionFees(transaction.destination)}`,
    },
  ];

  const columnThree = [
    { label: 'transactionId', value: transaction.transactionId },
    ...(showRecipient
      ? [
          {
            label: 'recipientNumber',
            value: transfers?.recipient?.phoneNumber,
            link,
          },
        ]
      : [
          {
            label: 'senderNumber',
            value: transfers?.sender?.phoneNumber,
            link,
          },
        ]),
    { label: 'createdDate', value: transaction?.createdDate && toUtcDateString(transaction.createdDate) },
  ];

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <TransactionView
      columnOne={columnOne}
      columnTwo={columnTwo}
      columnThree={columnThree}
    />
  );
};

export default Transfer;
