import { type CustomerDocStatus } from './Customer';

export enum VerificationStepStatus {
  AddressApproved = 'AddressApproved',
  AddressDocApproved = 'AddressDocApproved',
  IdvApproved = 'IdvApproved',
  IdentityDocApproved = 'IdentityDocApproved',
  EdvApproved = 'EdvApproved',
  SanctionsRetrieved = 'SanctionsRetrieved',
  IdvDocCheck = 'IdvDocCheck',
  IdvRejected = 'IdvRejected',
  RiskApproved = 'RiskApproved',
  IdvPiiApproved = 'IdvPiiApproved',
  AddressEdvApproved = 'AddressEdvApproved',
  AgeApproved = 'AgeApproved',
  AddressIdvApproved = 'AddressIdvApproved',
  IdvDocInput = 'IdvDocInput',
  AddressGeolocationApproved = 'AddressGeolocationApproved',
}

export interface KycApplicationResponseDetail {
  statusTo: VerificationStepStatus;
  timeStamp: string;
  details: KycIdvDetails;
}

export interface KycIdvDetails {
  idvSdkFlowId?: string;
  idvIndicators?: KycIdvIndicatorDetails[];
}

export interface KycIdvIndicatorDetails {
  result: string;
  description: string;
}

export enum KycApplicationResponseState {
  Approved = 'Approved',
}

export interface KycApplicationResponse {
  id: string;
  createdOn: string;
  customerId: string;
  state: KycApplicationResponseState;
  details: KycApplicationResponseDetail[];
  sessionId: string;
  documents: {
    address?: { id?: string; state?: CustomerDocStatus };
    identity?: { id?: string; state?: CustomerDocStatus };
  };
}

export interface KycApplications {
  id?: string;
  customerId: string;
  isApplicationApproved: boolean;
  edvApprovedDate?: string;
  idvApprovedDate?: string;
  idvDocApprovedDate?: string;
  addressDocApprovedDate?: string;
  idvPiiApprovedDate?: string;
  geolocationApprovedDate?: string;
  addressEdvApprovedDate?: string;
  addressVerifiedDate?: string;
  addressDocVerifiedDate?: string;
  isSanctionsRetrieved: boolean;
  applicationDate?: string;
  sanctionsRetrievedDate?: string;
  documents?: {
    identity?: { id?: string; state?: CustomerDocStatus };
    address?: { id?: string; state?: CustomerDocStatus };
  };
  idvDocCheckId?: KycIdvDetails;
  idvDocCheckStatuses?: KycIdvDetails;
  riskApprovedDate?: string;
}

export enum KycApplicationActionState {
  AcceptDocument = 'AcceptDocument',
  RejectDocument = 'RejectDocument',
}
