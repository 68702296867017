import { BrowserCacheLocation, type PopupRequest, PublicClientApplication } from '@azure/msal-browser';
import { EnvironmentConstants } from '@/utils/constants';

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */

const clientId = EnvironmentConstants.MSAL_CLIENT_ID ?? '';
const tenantId = EnvironmentConstants.MSAL_TENANT_ID ?? '';
const authority = `https://login.microsoftonline.com/${tenantId}`;

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId, // The Application (client) ID from your app registration
    authority,
    redirectUri: '/', // Replace with your redirect URI; must be registered in the Azure portal
    postLogoutRedirectUri: '/', // Replace with your post-logout redirect URI
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: false, // Set to true if you have issues on IE11 or Edge
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */

// Leave the prompt logic to check for 'dev' explicitly, as stg needs to match prod for release validation purposes.
export const loginRequest: PopupRequest = {
  scopes: ['profile'],
  prompt: EnvironmentConstants.RUNTIME_ENV === 'dev' ? 'select_account' : 'login',
};
