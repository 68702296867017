import { useAppDispatch, useAppSelector, type RootState } from '@/lib/redux';
import { useTranslation } from 'react-i18next';

import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle';
import Typography from '@/components/common/typography/Typography';
import Table from '@/components/common/tables/Table';
import { CountriesEnum, CurrencyEnum, type BusinessAccounts } from '@/types';
import VerticalLine from '@/components/common/vertical-line/VerticalLine';
import { marine } from '@/utils/colors';
import styles from './Overview.module.scss';
import Spinner from '@/components/common/spinners/Spinner';
import countries from '@/i18n/countriesConfig';
import BusinessAccountActionButtons from '@/components/business-accounts/action-buttons/BusinessAccountActionButtons';
import { getBusinessAccounts } from '@/lib/redux/slices/business-accounts/actions';
import FlagDisplay from '@/components/business-accounts/FlagDisplay/FlagDisplay';
import SomethingWentWrong from '@/components/common/something-went-wrong/SomethingWentWrong';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import { Permissions } from '@/types';

const Overview = (): React.JSX.Element => {
  const { t, i18n } = useTranslation('businessAccounts');
  const { businessAccounts, loading, isError } = useAppSelector((state: RootState) => state.businessAccounts);
  const dispatch = useAppDispatch();

  useDocumentTitle(t('overview-page-title'));

  const result = businessAccounts?.reduce((acc: BusinessAccounts[], item: BusinessAccounts) => {
    if (item.currencyCode !== CurrencyEnum.USD || item.countryCode === CountriesEnum.ECU) {
      acc.push({
        ...item,
        countryName: countries.getName(item.countryCode, i18n.language),
      });
    }
    return acc;
  }, []);

  const updateAction = (): void => {
    void dispatch(getBusinessAccounts());
  };

  if (isError) {
    return <SomethingWentWrong />;
  }

  return (
    <div>
      <Typography variant='p1SemiBold'>{t('tabs.overview.balance')}</Typography>
      {loading && <Spinner />}
      {!loading && (
        <Table cols={2}>
          {result?.map(account => (
            <Table.Row key={account.accountId}>
              <Table.Cell>
                <div className={styles.iconCell}>
                  <FlagDisplay countryCode={account.countryCode} />
                  <Typography variant='poSemibold'>{account.countryName}</Typography>
                </div>
                <VerticalLine
                  color={marine}
                  size='large'
                />
                <Typography
                  className={styles.currencyCode}
                  variant='poSemibold'
                >
                  {account.currencyCode}
                </Typography>
                <Typography variant='poSemibold'>{account.availableBalance}</Typography>
              </Table.Cell>
              <Table.Cell>
                <UserPermissionsGuard permission={Permissions.businessAccountsTxnAdjustments}>
                  <BusinessAccountActionButtons
                    accountId={account.accountId}
                    onAction={updateAction}
                  />
                </UserPermissionsGuard>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table>
      )}
    </div>
  );
};

export default Overview;
