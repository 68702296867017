export const EnvironmentConstants = {
  API_URL: process.env.REACT_APP_API_URL,
  API_SUBSCRIPTION_KEY: process.env.REACT_APP_API_SUBSCRIPTION_KEY,

  MSAL_CLIENT_ID: process.env.REACT_APP_MSAL_CLIENT_ID,
  MSAL_TENANT_ID: process.env.REACT_APP_MSAL_TENANT_ID,

  RUNTIME_ENV: process.env.REACT_APP_RUNTIME_ENV as RuntimeEnvironments,
};

export enum RuntimeEnvironments {
  Development = 'dev',
  Staging = 'stg',
  Production = 'pro',
}

export const AppUrls = {
  NotFoundUrl: '/not-found',
};

export enum ServerErrors {
  USER_ALREADY_EXISTS = 'err_user_already_exists',
}

export const PaymentProviderId = '665060125c98e783c832d63a';

export const PAYMENT_METHODS_TOPUP_NO_GROUP_ID = 'defined-as-null';
