import { type Option } from '@/utils/interfaces';
import { DocType, Action } from './ConfirmationModal';

export const reasonCodes = (actionType: string, docType: string): Option[] => {
  const approve = actionType === Action.Approve;
  const idDocs = DocType.idDocs === docType;
  const addressDocs = DocType.addressDocs === docType;
  return [
    ...(idDocs && approve ? [{ value: 'IDV001', text: 'IDV001 - Identity document accepted' }] : []),
    ...(idDocs && !approve
      ? [
          { value: 'IDV002', text: 'IDV002 - Alternative identity document needed' },
          { value: 'IDV003', text: 'IDV003 - 3rd party ID submitted, need ID of the registered person' },
          { value: 'IDV004', text: 'IDV004 - Blurry ID doc received, clear image needed' },
          { value: 'IDV005', text: 'IDV005 - Underage customer' },
          { value: 'IDV006', text: 'IDV006 - Altered ID doc received, no service' },
          { value: 'IDV007', text: 'IDV007 - Face mismatch, new face verification needed' },
          { value: 'IDV008', text: 'IDV008 - Expired ID submitted, valid ID needed' },
          { value: 'IDV009', text: 'IDV009 - Front and back side of the provided ID needed' },
          { value: 'IDV010', text: 'IDV010 - Unsupported ID doc submitted, acceptable ID doc needed' },
        ]
      : []),
    ...(addressDocs && approve ? [{ value: 'AV001', text: 'AV001 - AV doc accepted' }] : []),
    ...(addressDocs && !approve
      ? [
          { value: 'AV002', text: 'AV002 - Old AV doc submitted, recent AV needed' },
          { value: 'AV004', text: 'AV004 - Blurry AV doc submitted, clearer copy needed' },
          { value: 'AV005', text: 'AV005 - Altered AV doc submitted, no service - SUSPENDED' },
          { value: 'AV006', text: 'AV006 - Unsupported AV doc submitted, acceptable AV doc needed' },
          { value: 'AV007', text: 'AV007 - Missing name, address or date on submitted AV doc, proper AV doc needed' },
        ]
      : []),
  ];
};
