import { createSlice } from '@reduxjs/toolkit';
import {
  getPaymentMethodsWithdrawals,
  savePaymentMethodsWithdrawals,
  updatePaymentMethodsWithdrawals,
} from './actions';
import type PaymentMethodWithdrawals from '@/types/PaymentMethodWithdrawal';

export interface PaymentMethodsWithdrawalsState {
  paymentMethodsWithdrawals?: PaymentMethodWithdrawals;
  success: boolean;
  loading: boolean;
  createSuccess: boolean;
  updateSuccess: boolean;
}

const initialState: PaymentMethodsWithdrawalsState = {
  paymentMethodsWithdrawals: undefined,
  loading: false,
  success: false,
  createSuccess: false,
  updateSuccess: false,
};

export const paymentMethodsWithdrawals = createSlice({
  name: 'paymentMethodsWithdrawals',
  initialState,
  reducers: {
    reset() {
      return {
        paymentMethodsWithdrawals: initialState.paymentMethodsWithdrawals,
        loading: false,
        success: false,
        createSuccess: false,
        updateSuccess: false,
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(getPaymentMethodsWithdrawals.pending, state => {
      state.loading = true;
    });
    builder.addCase(getPaymentMethodsWithdrawals.fulfilled, (state, action) => {
      state.paymentMethodsWithdrawals = action.payload;
      state.loading = false;
      state.success = true;
      state.updateSuccess = false;
      state.createSuccess = false;
    });
    builder.addCase(getPaymentMethodsWithdrawals.rejected, state => {
      state.loading = false;
      state.success = false;
    });
    builder.addCase(savePaymentMethodsWithdrawals.pending, state => {
      state.loading = true;
    });
    builder.addCase(savePaymentMethodsWithdrawals.fulfilled, state => {
      state.loading = false;
      state.success = true;
      state.createSuccess = true;
    });
    builder.addCase(savePaymentMethodsWithdrawals.rejected, state => {
      state.loading = false;
      state.createSuccess = false;
    });
    builder.addCase(updatePaymentMethodsWithdrawals.pending, state => {
      state.loading = true;
    });
    builder.addCase(updatePaymentMethodsWithdrawals.fulfilled, state => {
      state.loading = false;
      state.updateSuccess = true;
    });
    builder.addCase(updatePaymentMethodsWithdrawals.rejected, state => {
      state.loading = false;
      state.updateSuccess = false;
    });
  },
});

export const { reset } = paymentMethodsWithdrawals.actions;
