export default function ErrorStamp({
  width = `${65 / 16}rem`,
  height = `${24 / 16}rem`,
  color = '#E75B56',
}: Readonly<{
  width?: string;
  height?: string;
  color?: string;
}>): React.JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 65 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        width='63'
        height='18'
        rx='9'
        fill={color}
      />
      <path
        d='M10.9067 3C7.64485 3 5 5.58458 5 8.77215C5 11.9597 7.64485 14.5443 10.9067 14.5443C14.1686 14.5443 16.8135 11.9597 16.8135 8.77215C16.8135 5.58458 14.1686 3 10.9067 3ZM10.4848 5.98915C10.4848 5.93246 10.5323 5.88608 10.5903 5.88608H11.2232C11.2812 5.88608 11.3286 5.93246 11.3286 5.98915V9.49367C11.3286 9.55036 11.2812 9.59675 11.2232 9.59675H10.5903C10.5323 9.59675 10.4848 9.55036 10.4848 9.49367V5.98915ZM10.9067 11.6582C10.7411 11.6549 10.5834 11.5883 10.4675 11.4727C10.3516 11.3571 10.2867 11.2017 10.2867 11.0398C10.2867 10.8779 10.3516 10.7225 10.4675 10.6069C10.5834 10.4913 10.7411 10.4246 10.9067 10.4213C11.0723 10.4246 11.23 10.4913 11.346 10.6069C11.4619 10.7225 11.5268 10.8779 11.5268 11.0398C11.5268 11.2017 11.4619 11.3571 11.346 11.4727C11.23 11.5883 11.0723 11.6549 10.9067 11.6582Z'
        fill='white'
        fillOpacity='0.85'
      />
      <path
        d='M22.222 13V3.9H27.981V5.057H23.509V7.813H27.357V8.957H23.509V11.843H27.981V13H22.222ZM29.3637 13V6.5H30.4557L30.5207 7.358H30.5987C30.9497 6.773 31.6647 6.422 32.5097 6.422C32.6657 6.422 32.8087 6.435 32.9127 6.461V7.709C32.7437 7.683 32.6787 7.683 32.4967 7.683C31.2877 7.683 30.5987 8.489 30.5987 9.906V13H29.3637ZM34.2133 13V6.5H35.3053L35.3703 7.358H35.4483C35.7993 6.773 36.5143 6.422 37.3593 6.422C37.5153 6.422 37.6583 6.435 37.7623 6.461V7.709C37.5933 7.683 37.5283 7.683 37.3463 7.683C36.1373 7.683 35.4483 8.489 35.4483 9.906V13H34.2133ZM41.9145 13.143C40.0165 13.143 38.5865 11.674 38.5865 9.75C38.5865 7.826 40.0165 6.357 41.9145 6.357C43.8125 6.357 45.2425 7.826 45.2425 9.75C45.2425 11.674 43.8125 13.143 41.9145 13.143ZM41.9145 11.986C43.0975 11.986 43.9555 11.037 43.9555 9.75C43.9555 8.463 43.0975 7.514 41.9145 7.514C40.7315 7.514 39.8735 8.463 39.8735 9.75C39.8735 11.037 40.7315 11.986 41.9145 11.986ZM46.9086 13V6.5H48.0006L48.0656 7.358H48.1436C48.4946 6.773 49.2096 6.422 50.0546 6.422C50.2106 6.422 50.3536 6.435 50.4576 6.461V7.709C50.2886 7.683 50.2236 7.683 50.0416 7.683C48.8326 7.683 48.1436 8.489 48.1436 9.906V13H46.9086Z'
        fill='white'
      />
    </svg>
  );
}
