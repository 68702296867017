import React, { useCallback, useMemo, useState } from 'react';
import ConfirmModalElement from '@/components/common/modals/confirmation/ConfirmModal';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import { updateStatus } from '@/lib/redux/slices/document-review/actions';
import styled from 'styled-components';
import { useKycApplications } from '@/utils/hooks/useKycApplications';
import { useTranslation } from 'react-i18next';
import { reset } from '@/lib/redux/slices/document-review/slice';
import { CustomerDocStatus } from '@/types';
import { type Option } from '@/utils/interfaces';
import { reasonCodes } from './reasonCodes';
import SomethingWentWrong from '@/components/common/something-went-wrong/SomethingWentWrong';
const TitleDiv = styled.div`
  text-align: center;
`;

export enum DocType {
  idDocs = 'idDocs',
  addressDocs = 'addressDocs',
}

export enum Action {
  Approve = 'approve',
  Decline = 'decline',
}

export default function ConfirmationModal({ documentType }: { documentType: string }): React.JSX.Element | null {
  const { selectedStatus, documentId, loading } = useAppSelector(state => state.documentReview);
  const { customer } = useAppSelector((state: RootState) => state.customer);
  const [selectReason, setSelectReason] = useState({ text: '', value: '' });
  const dispatch = useAppDispatch();
  const { t } = useTranslation('documentReviewQueue');

  const { kycInfo, isError } = useKycApplications(customer?.customerId ?? '');

  const onClose = useCallback(() => {
    dispatch(reset());
    setSelectReason({ text: '', value: '' });
  }, [dispatch]);

  const onConfirm = useCallback(() => {
    if (customer?.customerId && documentId && selectedStatus && kycInfo.id) {
      void dispatch(
        updateStatus({
          kycApplicationId: kycInfo.id,
          status: selectedStatus,
          documentId,
          documentReason: selectReason.value,
        }),
      );
    }
    setSelectReason({ text: '', value: '' });
  }, [dispatch, customer?.customerId, documentId, selectedStatus, kycInfo.id, selectReason]);

  const handleSelect = (opt: Option): void => {
    setSelectReason({ value: opt.value ?? '', text: opt.text });
  };

  const { action, reasons } = useMemo(() => {
    const reasons: Option[] = [];
    if (selectedStatus === CustomerDocStatus.APPROVED) {
      reasons.push(...reasonCodes(Action.Approve, documentType));
      return { action: Action.Approve, reasons };
    }
    reasons.push(...reasonCodes(Action.Decline, documentType));
    return { action: Action.Decline, reasons };
  }, [selectedStatus, documentType]);

  if (isError) {
    return <SomethingWentWrong />;
  }

  if (!selectedStatus) {
    return null;
  }

  return (
    <ConfirmModalElement
      onConfirm={onConfirm}
      onDeny={onClose}
      title={<TitleDiv>{t('confirmation-modal.confirm-document-message', { action: t(action) })}</TitleDiv>}
      disabled={loading}
      options={reasons}
      onSelect={handleSelect}
      selectedOption={selectReason}
    />
  );
}
