import featureFlags from '@/assets/featureFlags.json';
import { EnvironmentConstants } from '@/utils/constants';

const featureFlagsData = featureFlags[EnvironmentConstants.RUNTIME_ENV] || {};

type FeatureFlags = {
  [K in FeatureFlagKeys]?: boolean;
};

enum FeatureFlagKeys {
  FeatureDailyLimits = 'featureDailyLimits',
  FFConfigs = 'ffConfig',
  FeatureCardChargeback = 'featureCardChargeback',
  PaymentMethodTopupActions = 'paymentMethodTopupActions',
  CountryConfLimits = 'countryConfLimits',
};

export { featureFlagsData, FeatureFlagKeys, type FeatureFlags };
