import { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useAppSelector, useAppDispatch } from '@/lib/redux';
import Typography from '@/components/common/typography/Typography';
import { mediumPurple } from '@/utils/colors';
import InputLabel from '@/components/common/labels/InputLabel';
import Input from '@/components/common/inputs/Input';
import Accordion from '@/components/common/accordion/Accordion';
import { AccordionTitle } from '@/components/configurations';
import {
  resetComplianceDocsPolicy,
  resetComplianceDocsTermsAndCondition,
  resetHasExistingPolicyVersion,
  resetHasExistingTermsAndConditionsVersion,
} from '@/lib/redux/slices/compliance-docs/slice';
import { objectDeepEquality } from '@/utils/helperFunctions';
import { addPrivacyPolicy, addTermsAndConditions } from '@/lib/redux/slices/compliance-docs/actions';
import { resetSucces } from '@/lib/redux/slices/country/slice';
import Button from '@/components/common/button/Button';
import { type ViewMode } from '@/types';

import styles from './ComplianceDocs.module.scss';

const GridItem = styled.div<{ $cell?: [number?, number?]; $align?: string }>`
  grid-column: ${({ $cell = [1, 1] }) => {
    const start = $cell[0];
    const span = $cell[1] ?? 1;
    return `${start} / span ${span}`;
  }};
  display: flex;
  flex-direction: row;
  justify-content: ${({ $align }) => $align ?? 'center'};
  align-content: center;
  @media (max-width: 1090px) {
    grid-column: 1 / span 5;
  }
`;

interface ComplianceDocsProps {
  mode: ViewMode;
}

const ComplianceDocs = ({ mode }: Readonly<ComplianceDocsProps>): JSX.Element => {
  const { t } = useTranslation('configurations');

  const dispatch = useAppDispatch();
  const {
    policy: compliancePolicy,
    termsAndCondition: complianceTermsAndCondition,
    successfulPolicy,
    successfulTermsAndCondition,
    hasExistingPolicyVersion,
    hasExistingTermsAndConditionsVersion,
  } = useAppSelector(state => state.complianceDocs);
  const { country, isLoading } = useAppSelector(state => state.country);

  const [active, setActive] = useState<boolean>(false);
  const [policyParameters, setPolicyParameters] = useState({
    ...compliancePolicy,
    countryCode: country.countryCode,
  });
  const [termsAndConditionParameters, setTermsAndConditionParameters] = useState({
    ...complianceTermsAndCondition,
    countryCode: country.countryCode,
  });

  const isPolicyExisting = compliancePolicy.countryCode.length > 0;
  const isTermsAndConditionExisting = complianceTermsAndCondition.countryCode.length > 0;

  const isPolicyVersionInvalid = useMemo(() => {
    if (isPolicyExisting && policyParameters.complianceDocUrl !== compliancePolicy.complianceDocUrl) {
      return policyParameters.complianceDocVersion === compliancePolicy.complianceDocVersion;
    }
    return false;
  }, [isPolicyExisting, policyParameters, compliancePolicy]);

  const isTermsAndConditionVersionInvalid = useMemo(() => {
    if (
      isTermsAndConditionExisting &&
      termsAndConditionParameters.complianceDocUrl !== complianceTermsAndCondition.complianceDocUrl
    ) {
      return termsAndConditionParameters.complianceDocVersion === complianceTermsAndCondition.complianceDocVersion;
    }
    return false;
  }, [isTermsAndConditionExisting, termsAndConditionParameters, complianceTermsAndCondition]);

  useEffect(() => {
    if (successfulTermsAndCondition) {
      dispatch(resetComplianceDocsTermsAndCondition());
    }
  }, [successfulTermsAndCondition, dispatch]);

  useEffect(() => {
    if (successfulPolicy) {
      dispatch(resetComplianceDocsPolicy());
    }
  }, [successfulPolicy, dispatch]);

  useEffect(() => {
    if (country.countryCode.length > 0 && policyParameters.countryCode.length === 0) {
      setPolicyParameters({ ...policyParameters, countryCode: country.countryCode });
    }
  }, [policyParameters, country]);

  useEffect(() => {
    if (country.countryCode.length > 0 && termsAndConditionParameters.countryCode.length === 0) {
      setTermsAndConditionParameters({ ...termsAndConditionParameters, countryCode: country.countryCode });
    }
  }, [termsAndConditionParameters, country]);

  const isReadOnly = mode === 'read';
  const { allDocsFieldsValid } = useMemo(() => {
    const {
      complianceDocVersion: policyVersion,
      countryCode: policyCountryCode,
      complianceDocUrl: policyUrl,
    } = policyParameters;

    const {
      complianceDocVersion: termsVersion,
      countryCode: termsCountryCode,
      complianceDocUrl: termsUrl,
    } = termsAndConditionParameters;
    // List of all fields to check
    const fields = [policyVersion, policyCountryCode, policyUrl, termsVersion, termsCountryCode, termsUrl];
    // Count the number of filled fields
    const filledFieldsCount = fields.filter(field => field.length > 0).length;
    // Check if all fields are filled
    const hasNonEmptyFields =
      policyVersion.length > 0 || policyUrl.length > 0 || termsVersion.length > 0 || termsUrl.length > 0;
    const allDocsFieldsValid =
      (filledFieldsCount === fields.length || !isPolicyVersionInvalid || !isTermsAndConditionVersionInvalid) &&
      hasNonEmptyFields;
    return {
      allDocsFieldsValid,
    };
  }, [isPolicyVersionInvalid, isTermsAndConditionVersionInvalid, policyParameters, termsAndConditionParameters]);

  const isComplianceDocsChanged =
    !objectDeepEquality(policyParameters, compliancePolicy) ||
    !objectDeepEquality(termsAndConditionParameters, complianceTermsAndCondition);

  const handleComplianceDocsSubmit = useCallback((): void => {
    if (!objectDeepEquality(termsAndConditionParameters, complianceTermsAndCondition)) {
      void dispatch(addTermsAndConditions({ body: { ...termsAndConditionParameters } }));
    }
    if (!objectDeepEquality(policyParameters, compliancePolicy)) {
      void dispatch(addPrivacyPolicy({ body: { ...policyParameters } }));
    }
    if (isPolicyExisting) {
      void dispatch(resetSucces());
    }
  }, [
    dispatch,
    termsAndConditionParameters,
    policyParameters,
    complianceTermsAndCondition,
    compliancePolicy,
    isPolicyExisting,
  ]);

  const handleSubmit = (): void => {
    if (allDocsFieldsValid) {
      handleComplianceDocsSubmit();
    }
  };

  const checkSubmitDisabled = useMemo((): boolean => {
    if (isLoading) return true;

    if (isPolicyExisting) {
      return !isComplianceDocsChanged || !allDocsFieldsValid;
    }

    return !allDocsFieldsValid;
  }, [isLoading, isPolicyExisting, isComplianceDocsChanged, allDocsFieldsValid]);

  const changePolicyVersion = (value: string): void => {
    setPolicyParameters({ ...policyParameters, complianceDocVersion: value });
    if (hasExistingPolicyVersion) {
      dispatch(resetHasExistingPolicyVersion());
    }
  };

  const changeTermsAndConditionsVersion = (value: string): void => {
    setTermsAndConditionParameters({ ...termsAndConditionParameters, complianceDocVersion: value });
    if (hasExistingTermsAndConditionsVersion) {
      dispatch(resetHasExistingTermsAndConditionsVersion());
    }
  };

  const renderContent = (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <div className={styles.label}>
          <Typography
            variant='p3'
            color={mediumPurple.cssColor}
          >
            {t('new-country.compliance-docs-description')}
          </Typography>
        </div>
        <div className={styles.inputCard}>
          <div className={styles.gridCard}>
            <GridItem
              $align='start'
              $cell={[1]}
            >
              <Typography variant='p3Bold'>{t('new-country.terms-&-conditions')}</Typography>
            </GridItem>
            <GridItem $cell={[1, 2]}>
              <InputLabel label={t('new-country.input-label-docs.version')}>
                <Input
                  error={
                    isTermsAndConditionVersionInvalid || hasExistingTermsAndConditionsVersion
                      ? t('new-country.input-error.version')
                      : undefined
                  }
                  focused={
                    isTermsAndConditionExisting &&
                    complianceTermsAndCondition.complianceDocVersion !==
                      termsAndConditionParameters.complianceDocVersion
                  }
                  onChange={changeTermsAndConditionsVersion}
                  value={termsAndConditionParameters.complianceDocVersion}
                  disabled={isReadOnly}
                />
              </InputLabel>
            </GridItem>
            <GridItem $cell={[3, 2]}>
              <InputLabel label={t('new-country.input-label-docs.country-code')}>
                <Input
                  value={country.countryCode}
                  disabled={true}
                />
              </InputLabel>
            </GridItem>
            <GridItem $cell={[5, 2]}>
              <InputLabel label={t('new-country.input-label-docs.url')}>
                <Input
                  focused={
                    isTermsAndConditionExisting &&
                    complianceTermsAndCondition.complianceDocUrl !== termsAndConditionParameters.complianceDocUrl
                  }
                  onChange={(value: string) =>
                    setTermsAndConditionParameters({ ...termsAndConditionParameters, complianceDocUrl: value })
                  }
                  value={termsAndConditionParameters.complianceDocUrl}
                  disabled={isReadOnly}
                />
              </InputLabel>
            </GridItem>
            <GridItem
              $align='start'
              $cell={[1]}
            >
              <Typography variant='p3Bold'>{t('new-country.privacy-policy')}</Typography>
            </GridItem>
            <GridItem $cell={[1, 2]}>
              <InputLabel label={t('new-country.input-label-docs.version')}>
                <Input
                  error={
                    isPolicyVersionInvalid || hasExistingPolicyVersion
                      ? t('new-country.input-error.version')
                      : undefined
                  }
                  focused={
                    isPolicyExisting && compliancePolicy.complianceDocVersion !== policyParameters.complianceDocVersion
                  }
                  onChange={changePolicyVersion}
                  value={policyParameters.complianceDocVersion}
                  disabled={isReadOnly}
                />
              </InputLabel>
            </GridItem>
            <GridItem $cell={[3, 2]}>
              <InputLabel label={t('new-country.input-label-docs.country-code')}>
                <Input
                  value={country.countryCode}
                  disabled={true}
                />
              </InputLabel>
            </GridItem>
            <GridItem $cell={[5, 2]}>
              <InputLabel label={t('new-country.input-label-docs.url')}>
                <Input
                  focused={isPolicyExisting && compliancePolicy.complianceDocUrl !== policyParameters.complianceDocUrl}
                  onChange={(value: string) => setPolicyParameters({ ...policyParameters, complianceDocUrl: value })}
                  value={policyParameters.complianceDocUrl}
                  disabled={isReadOnly}
                />
              </InputLabel>
            </GridItem>
          </div>
        </div>
      </div>
      {!isReadOnly && (
        <div className={styles.buttons}>
          <Button
            textTransform='uppercase'
            disabled={checkSubmitDisabled}
            onClick={handleSubmit}
          >
            {t('new-country.button-submit-configuration')}
          </Button>
        </div>
      )}
    </div>
  );

  return (
    <Accordion
      title={
        <AccordionTitle
          isValid={isPolicyExisting && !isComplianceDocsChanged}
          title='Compliance Docs'
        />
      }
      content={renderContent}
      isActive={active}
      onClick={() => setActive(!active)}
    />
  );
};

export default ComplianceDocs;
