import { toRem } from 'k8-web-lib-tmp';
import styled from 'styled-components';

import { extraLightWhite, purple } from '@/utils/colors';

export const LimitLevelsLabel = styled.span`
  padding: 0 ${toRem(12)};
  font-size: ${toRem(13)};
  font-weight: 500;
`;

export const LimitLevelsContainer = styled.div`
  display: inline-block;
  margin-bottom: ${toRem(20)};
  min-width: ${toRem(165)};
`;

export const AccordeonTitleStyled = styled.div`
  display: flex;
  align-items: center;
  gap: ${toRem(12)};
`;

export const GridContainerHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: ${toRem(20)};
  margin-bottom: ${toRem(24)};
  background-color: ${extraLightWhite.cssColor};
  padding: ${toRem(8)};
  font-size: ${toRem(15)};
  font-weight: 500;
  color: ${purple.cssColor};
`;

export const GridItem = styled.div<{ $cell?: [number?, number?] }>`
  grid-column: ${({ $cell = [1, 1] }) => {
    const start = $cell[0];
    const span = $cell[1] ?? 1;
    return `${start} / span ${span}`;
  }};
  @media (max-width: 1090px) {
    grid-column: 1 / span 7;
  }
`;
