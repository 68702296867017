import { useCallback, useMemo, useEffect, type FC } from 'react';
import { Select } from 'k8-web-lib-tmp';

import { type Option } from '@/utils/interfaces';
import { useAppDispatch, useAppSelector } from '@/lib/redux';
import { setSelectedLimitLevel } from '@/lib/redux/slices/limits/slice';
import { usePermissions } from '@/utils/hooks/usePermissions';
import { Permissions } from '@/types';

import { LimitLevelsContainer, LimitLevelsLabel } from './styles';

const acceptedLimitLevelsNames = ['Standard', 'Entry'];

const LimitLevels: FC = () => {
  const dispatch = useAppDispatch();
  const { loading, limitLevels, selectedLevel } = useAppSelector(state => state.limits);
  const hasCountryEditPermission = usePermissions(Permissions.countryConfigEdit);

  useEffect(() => {
    if (limitLevels && limitLevels.length > 0) {
      const standardLevel = limitLevels.filter(limitLevel => limitLevel.name === acceptedLimitLevelsNames[0])[0];

      if (standardLevel) {
        dispatch(setSelectedLimitLevel({ text: standardLevel.name, value: standardLevel.id }));
      }
    }
  }, [limitLevels, dispatch]);

  const onSelect = useCallback(
    (option: Option) => {
      dispatch(setSelectedLimitLevel(option));
    },
    [dispatch],
  );

  const prepareLimitLevelsOptions = useMemo((): Option[] => {
    let limitLevelsOptions: Option[] = [];
    if (limitLevels) {
      limitLevelsOptions = limitLevels
        .filter(limitLevel => acceptedLimitLevelsNames.includes(limitLevel.name))
        .map(limitLevel => {
          return {
            text: limitLevel.name,
            value: limitLevel.id,
          };
        });
    }

    return limitLevelsOptions;
  }, [limitLevels]);

  return (
    <div>
      <LimitLevelsLabel>Customer level</LimitLevelsLabel>
      <LimitLevelsContainer>
        <Select
          options={prepareLimitLevelsOptions}
          selected={selectedLevel}
          onSelect={onSelect}
          loading={loading}
          disabled={!hasCountryEditPermission}
        />
      </LimitLevelsContainer>
    </div>
  );
};

export default LimitLevels;
