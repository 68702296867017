import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { searchChannels, getChannelById } from '@/lib/redux/slices/channels-search/actions';
import { type Channel } from '@/types/Channel';
import { type Option } from '@/utils/interfaces';

export interface ChannelsSearchState {
  channels: Channel[];
  channelsOptions: Option[];
  loading: boolean;
  success: boolean;
  selectedChannel: Option | undefined;
  selectedChannelLoading: boolean;
  selectedChannelSuccess: boolean;
}

const initialState: ChannelsSearchState = {
  channels: [],
  channelsOptions: [],
  loading: false,
  success: false,
  selectedChannel: undefined,
  selectedChannelLoading: false,
  selectedChannelSuccess: false,
};

export const channelsSearchSlice = createSlice({
  name: 'channels-search',
  initialState,
  reducers: {
    setSelectedChannel(
      state,
      action: {
        payload: Option | undefined;
      },
    ) {
      state.selectedChannel = action.payload;
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(searchChannels.pending, state => {
      state.loading = true;
    });
    builder.addCase(searchChannels.fulfilled, (state, action: PayloadAction<Channel[]>) => {
      state.channels = action.payload ?? [];
      state.channelsOptions =
        action.payload?.map(({ name, id }) => ({
          text: name,
          value: id,
        })) ?? [];
      state.loading = false;
      state.success = true;
    });
    builder.addCase(searchChannels.rejected, state => {
      state.channels = [];
      state.loading = false;
    });

    builder.addCase(getChannelById.pending, state => {
      state.selectedChannelLoading = true;
    });
    builder.addCase(getChannelById.fulfilled, (state, { payload }) => {
      state.selectedChannel = {
        text: payload.name,
        value: payload.id,
      };
      state.selectedChannelLoading = false;
      state.selectedChannelSuccess = true;
    });
    builder.addCase(getChannelById.rejected, state => {
      state.selectedChannelLoading = false;
    });
  },
});

export const { setSelectedChannel, reset } = channelsSearchSlice.actions;
