import { useTranslation } from 'react-i18next';
import CustomerPiiField from '@/components/customer/customer-pii/data/CustomerPiiField';
import { toUtcDate, toUtcDateString } from '@/utils/dateTimeUtils';
import { useEffect, useMemo } from 'react';
import { fetchPaymentCards } from '@/lib/redux/slices/payment-cards/actions';
import { useAppDispatch } from '@/lib/redux';

import { usePaymentCards } from '@/utils/hooks/usePaymentCards';

interface KuadyCardsDataProps {
  customerId: string;
}

export default function KuadyCardsData({ customerId }: Readonly<KuadyCardsDataProps>): React.JSX.Element {
  const { t } = useTranslation('customerOverview');

  const { paymentCards, isError } = usePaymentCards(customerId);
  const { physicalCard, virtualCard } = paymentCards;
  const dispatch = useAppDispatch();

  const calculatedDate = useMemo(() => {
    if (
      getUtcTime(physicalCard?.termsAndConditions?.createdAt) > getUtcTime(virtualCard?.termsAndConditions?.createdAt)
    ) {
      return physicalCard?.termsAndConditions?.createdAt;
    } else {
      return virtualCard?.termsAndConditions?.createdAt;
    }
  }, [physicalCard, virtualCard]);

  function getUtcTime(date?: string): number {
    const d = toUtcDate(new Date(date ?? 0));
    return date != null ? d.getTime() : 0;
  }

  useEffect(() => {
    if (customerId) {
      void dispatch(fetchPaymentCards({ customerId }));
    }
  }, [customerId, dispatch]);

  return (
    <CustomerPiiField
      fieldName={t('widgets.customer-pii.kuady-cards.card-title')}
      isError={isError}
    >
      {calculatedDate && toUtcDateString(calculatedDate)}
    </CustomerPiiField>
  );
}
