import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import { reset } from '@/lib/redux/slices/transaction-history/slice';
import { fetchSingleTransaction } from '@/lib/redux/slices/transaction-history-detail/actions';
import WidgetBox from '@/components/customer/widget-box/WidgetBox';
import styles from '@/components/customer/transaction-history/TransactionHistoryDetailWidget.module.scss';
import Spinner from '@/components/common/spinners/Spinner';
import BackButton from '@/components/common/button/implementations/BackButton';
import Button from '@/components/common/button/Button';
import {
  type ActionOnCloseParams,
  TransactionActions,
} from '@/components/customer/transaction-history/transaction-actions/TransactionActions';
import { type Transaction, TransactionType } from '@/types';
import {
  Adjustment,
  Common,
  CustomerPayment,
  Exchange,
  Topup,
  Transfer,
  Withdrawal,
} from '@/components/customer/transaction-history/transaction-details';
import useBackButtonCallback from '@/utils/hooks/useBackButtonCallback';
import Reversal from '@/components/customer/transaction-history/transaction-details/Reversal';
import ChargebackReversal from '@/components/customer/transaction-history/transaction-details/ChargebackReversal';
import Chargeback from '@/components/customer/transaction-history/transaction-details/Chargeback';
import { reset as transactionActionsReset } from '@/lib/redux/slices/transaction-actions/slice';
import { fetchAccounts } from '@/lib/redux/slices/accounts/actions';
import Cards from './transaction-details/Cards';

export type TransactionWithSlipId = Omit<Transaction, 'slipId' | 'transactionId'> & {
  slipId: string;
  transactionId: string;
  metadata: string;
};

function TransactionHistoryDetailWidget(): React.JSX.Element {
  const { t } = useTranslation(['customerOverview', 'global']);

  const { transactionId } = useParams<{ transactionId: string }>();
  const { transaction, isLoading } = useAppSelector((state: RootState) => state.singleTransactionSlice);
  const { customerId } = useParams<{ customerId: string }>();
  const [openTransactionsModal, setOpenTransactionsModal] = useState(false);
  const dispatch = useAppDispatch();
  const goBackCb = useBackButtonCallback();

  useEffect(() => {
    if (transactionId) void dispatch(fetchSingleTransaction({ transactionId }));
  }, [transactionId, dispatch]);

  useEffect(
    () => () => {
      void dispatch(reset());
      void dispatch(transactionActionsReset());
    },
    [dispatch],
  );
  const navigate = useNavigate();

  const handleCloseModal = (params?: ActionOnCloseParams): void => {
    if (params?.success && customerId) {
      if (!params?.disableNavigate) {
        navigate(`/customer/${customerId}/transaction-history`);
      }
      void dispatch(fetchAccounts(customerId));

      if (params.refreshTransaction && transactionId) {
        void dispatch(fetchSingleTransaction({ transactionId }));
      }
    }
    setOpenTransactionsModal(false);
  };

  const handleOpenTransactionsModal = (): void => setOpenTransactionsModal(true);

  const transactionType = transaction?.transactionType;

  let transactionDetails;

  if (transactionId === transaction?.transactionId) {
    if (transaction?.slipId) {
      switch (transactionType) {
        case TransactionType.Withdrawal:
          transactionDetails = <Withdrawal transaction={transaction as TransactionWithSlipId} />;
          break;
        case TransactionType.Topup:
          transactionDetails = <Topup transaction={transaction as TransactionWithSlipId} />;
          break;
        case TransactionType.Transfer:
          transactionDetails = <Transfer transaction={transaction as TransactionWithSlipId} />;
          break;
        case TransactionType.AdjustmentDebit:
        case TransactionType.AdjustmentCredit:
          transactionDetails = <Adjustment transaction={transaction as TransactionWithSlipId} />;
          break;
        case TransactionType.PaymentFromCustomer:
        case TransactionType.PaymentToCustomer:
          transactionDetails = <CustomerPayment transaction={transaction as TransactionWithSlipId} />;
          break;
        case TransactionType.Exchange:
          transactionDetails = <Exchange transaction={transaction as TransactionWithSlipId} />;
          break;
        case TransactionType.MerchantPaymentReversal:
        case TransactionType.Reversal:
          transactionDetails = <Reversal transaction={transaction as TransactionWithSlipId} />;
          break;
        case TransactionType.Chargeback:
          transactionDetails = <Chargeback transaction={transaction as TransactionWithSlipId} />;
          break;
        case TransactionType.ChargebackReversal:
          transactionDetails = <ChargebackReversal transaction={transaction as TransactionWithSlipId} />;
          break;
        case TransactionType.Card:
          transactionDetails = <Cards transaction={transaction as TransactionWithSlipId} />;
          break;
      }
    }
    if (!transactionDetails) {
      transactionDetails = <Common transaction={transaction as TransactionWithSlipId} />;
    }
  }

  return (
    <>
      <WidgetBox>
        <div className={styles['transactions-widget-single__top-section']}>
          <BackButton onClick={goBackCb}>{t('global:buttons.back')}</BackButton>
          <Button
            onClick={handleOpenTransactionsModal}
            textTransform='uppercase'
          >
            {t('widgets.transaction-history-details.button-manual-transactions')}
          </Button>
        </div>
        <div className={styles['transactions-widget-single__table-wrapper']}>
          {isLoading && <Spinner />}
          {!isLoading && transaction && transactionDetails}
        </div>
      </WidgetBox>
      {openTransactionsModal && transaction && (
        <TransactionActions
          onClose={handleCloseModal}
          open={openTransactionsModal}
          transaction={transaction}
        />
      )}
    </>
  );
}

export default TransactionHistoryDetailWidget;
