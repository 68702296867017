import { useState } from 'react';

import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';

import CreatePaymentMethodButton from '@/components/configurations/payment-method-group-topup/CreateMethodGroupButton';
import PaymentMethodGroupTopupModal from '@/components/configurations/payment-method-group-topup/PaymentMethodGroupTopupModal';
import PaymentMethodTopupModal from '@/components/configurations/payment-method-topup/PaymentMethodTopupModal';
import PaymentMethodsGroupsTable from '@/components/configurations/payment-method-group-topup/PaymentMethodsGroupsTable';
import PaymentMethodsTable from '@/components/configurations/payment-method-group-topup/PaymentMethodsTable';
import styles from '@/pages/configurations/countries-configurations/CountriesConfigurations.module.scss';
import { Permissions } from '@/types';

const PaymentMethodsTopUp = (): JSX.Element => {
  const [activeGroupId, setActiveGroupId] = useState('');

  if (activeGroupId !== '') {
    return (
      <>
        <UserPermissionsGuard permission={Permissions.countryConfigRead}>
          <PaymentMethodsTable
            groupId={activeGroupId}
            setActiveGroupId={setActiveGroupId}
          />
        </UserPermissionsGuard>
        <PaymentMethodTopupModal />
      </>
    );
  }

  return (
    <>
      <div className={styles.titleTopup}>
        <UserPermissionsGuard permission={Permissions.countryConfigEdit}>
          <div className={styles.buttons}>
            <CreatePaymentMethodButton />
          </div>
        </UserPermissionsGuard>
      </div>
      <div className={styles.emptyCard}>
        <UserPermissionsGuard permission={Permissions.countryConfigRead}>
          <PaymentMethodsGroupsTable setActiveGroupId={setActiveGroupId} />
        </UserPermissionsGuard>
      </div>
      <PaymentMethodGroupTopupModal />
    </>
  );
};

export default PaymentMethodsTopUp;
