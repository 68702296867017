import authAxios from '@/auth/axios';
import type { DeviceInfoType } from '@/types';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchDeviceInfo = createAsyncThunk('risk.deviceInfo', async (phoneNumber: string, { rejectWithValue }) => {
  try {
    const { data } = await authAxios.get<{ data: any }>(`/threatmetrix/${phoneNumber}/last/10`);

    const response = data.data;
    const ipAddresses = response.riskScores
      .map((riskScore: any) => riskScore.riskData?.true_ip)
      .filter((ip: string) => ip !== undefined) as string[];

    const latestRiskScore = response.riskScores.length > 0 ? response.riskScores[0] : {};
    const riskData = latestRiskScore?.riskData;
    let appVersion = '';
    if (typeof riskData?.agent_app_info === 'string') {
      appVersion = firstAppVersion(riskData.agent_app_info as string);
    }

    const deviceInfo: DeviceInfoType = {
      deviceId: response.deviceId,
      phoneNumber: response.phoneNumber,
      riskScore: {
        requestDateTime: riskData?.requestDateTime,
        riskData: {
          brand: riskData?.agent_brand,
          model: riskData?.device_model,
          os: riskData?.os,
          osVersion: riskData?.os_version,
          ipAddress: riskData?.true_ip,
          ipCountry: riskData?.true_ip_geo,
          trustedDevices: Array.from(new Set<string>(ipAddresses)),
          agentAppInfo: riskData?.agent_app_info && appVersion,
        },
      },
    };

    return deviceInfo;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});

const firstAppVersion = (input: string): string => {
  const regex = /^[^:]*:(.*):[^:]*$/;
  const match = regex.exec(input);
  if (match) {
    return match[1];
  }
  return '';
};
