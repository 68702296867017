import ConfirmationModal from '@/components/common/modals/confirmation/ConfirmModal';
import { type FeatureFlag } from '@/types/FeatureFlag';

interface Props {
  title: string;
  setIsConfirmationOpen: (isOpen: boolean) => void;
  editFeatureFlag: (flag: FeatureFlag) => void;
  flag: FeatureFlag;
}

const FeatureConfirmModal = ({ title, setIsConfirmationOpen, editFeatureFlag, flag }: Readonly<Props>): JSX.Element => {
  const onDeny = (): void => {
    setIsConfirmationOpen(false);
  };

  const onConfirm = (): void => {
    editFeatureFlag(flag);
    setIsConfirmationOpen(false);
  };

  return (
    <ConfirmationModal
      title={title}
      onDeny={onDeny}
      onConfirm={onConfirm}
    />
  );
};

export default FeatureConfirmModal;
