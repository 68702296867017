import React from 'react';
import { useTranslation } from 'react-i18next';
import { toUtcDateString } from '@/utils/dateTimeUtils';
import { useKycApplications } from '@/utils/hooks/useKycApplications';
import { type Customer } from '@/types';
import Table from '@/components/common/tables/Table';
import WidgetBox from '@/components/customer/widget-box/WidgetBox';
import SomethingWentWrong from '@/components/common/something-went-wrong/SomethingWentWrong';
import Typography from '@/components/common/typography/Typography';
import styles from './PepSanctionChecks.module.scss';

export interface PepSanctionChecksProps {
  customer: Customer;
}

const PepSanctionChecks: React.FC<PepSanctionChecksProps> = ({ customer }) => {
  const { t } = useTranslation('customerOverview');
  const { kycInfo, isError } = useKycApplications(customer.customerId ?? '');

  if (isError) {
    return <SomethingWentWrong />;
  }

  const timestamp = kycInfo.sanctionsRetrievedDate;
  const isPepChecked = Boolean(customer.officialPep);
  const isSanctionChecked = Boolean(customer.isSanctionResult);
  const isSanctionsRetrieved = kycInfo.isSanctionsRetrieved;

  const sanctionRetrieved = isSanctionsRetrieved ? t('widgets.pep-and-sactions.yes') : t('widgets.pep-and-sactions.no');

  const handleResult = (isChecked: boolean): string => {
    if (isSanctionsRetrieved && isChecked) {
      return t('widgets.pep-and-sactions.positive');
    }
    if (isSanctionsRetrieved && !isChecked) {
      return t('widgets.pep-and-sactions.negative');
    }
    return 'N/A';
  };

  const sanctionTimestamp = isSanctionsRetrieved && timestamp && toUtcDateString(timestamp);

  return (
    <WidgetBox title={t('widgets.pep-and-sactions.header-title')}>
      <div className={styles.container}>
        <Table cols={4}>
          <Table.Head>
            <div />
          </Table.Head>
          <Table.Head>
            <Typography variant='p2Medium'>{t('widgets.pep-and-sactions.checked')}</Typography>
          </Table.Head>
          <Table.Head>
            <Typography variant='p2Medium'>{t('widgets.pep-and-sactions.result')}</Typography>
          </Table.Head>
          <Table.Head>
            <div />
          </Table.Head>
          <Table.Row>
            <Table.Cell>PEP</Table.Cell>
            <Table.Cell>{sanctionRetrieved}</Table.Cell>
            <Table.Cell>{handleResult(isPepChecked)}</Table.Cell>
            <Table.Cell>{sanctionTimestamp}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Sanctions</Table.Cell>
            <Table.Cell>{sanctionRetrieved}</Table.Cell>
            <Table.Cell>{handleResult(isSanctionChecked)}</Table.Cell>
            <Table.Cell>{sanctionTimestamp}</Table.Cell>
          </Table.Row>
        </Table>
        <div className={styles.riskRatingLabel}>
          {t('widgets.customer-pii.account-data.risk-rating')} : {customer.amlRating ?? 'N/A'}
        </div>
      </div>
    </WidgetBox>
  );
};

export default PepSanctionChecks;
