import { useCallback, useEffect, useRef } from 'react';
import { Checkbox, Select } from 'k8-web-lib-tmp';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '@/lib/redux';

import { GridContainer, GridItem, CheckboxLabel, CheckboxWrapper, TitleContainer } from './styles';
import Typography from '@/components/common/typography/Typography';
import InputLabel from '@/components/common/labels/InputLabel';
import Input from '@/components/common/inputs/Input';
import { searchChannels, getChannelById } from '@/lib/redux/slices/channels-search/actions';
import { type PaymentMethodWithdrawal, type WithdrawalFeeAmount } from '@/types/PaymentMethodWithdrawal';
import { type Option } from '@/utils/interfaces';
import { setSelectedChannel, reset } from '@/lib/redux/slices/channels-search/slice';

interface PaymentOptionProps {
  paymentMethod: PaymentMethodWithdrawal;
  setPaymentMethod: (method: PaymentMethodWithdrawal) => void;
  feeAmount: WithdrawalFeeAmount;
  setFeeAmount: (method: WithdrawalFeeAmount) => void;
  hasEditPermission: boolean | undefined;
}

const PaymentOption = ({
  paymentMethod,
  setPaymentMethod,
  feeAmount,
  setFeeAmount,
  hasEditPermission,
}: Readonly<PaymentOptionProps>): JSX.Element => {
  const { t } = useTranslation('configurations');
  const dispatch = useAppDispatch();
  const isInitialChannelNameLoadedRef = useRef<boolean>();
  const { loading, channelsOptions, selectedChannel } = useAppSelector(state => state.channelsSearch);

  useEffect(() => {
    void dispatch(
      searchChannels({
        transactionType: 'Withdrawal',
        limit: 5,
      }),
    );

    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isInitialChannelNameLoadedRef.current === undefined) {
      if (paymentMethod.channelId) {
        void dispatch(getChannelById(paymentMethod.channelId));
        isInitialChannelNameLoadedRef.current = true;
      }
    }
  }, [paymentMethod, isInitialChannelNameLoadedRef, dispatch]);

  const onSelect = useCallback(
    (option: Option) => {
      dispatch(setSelectedChannel(option));
      setPaymentMethod({ ...paymentMethod, channelId: option.value ?? '' });
    },
    [dispatch, paymentMethod, setPaymentMethod],
  );

  const onSearch = useCallback(
    (value: string): void => {
      void dispatch(
        searchChannels({
          transactionType: 'Withdrawal',
          name: value,
          limit: 5,
        }),
      );
    },
    [dispatch],
  );

  return (
    <>
      <TitleContainer>
        <Typography variant='p1MediumBold'>{t('pm-withdrawals.payment-option')}</Typography>
      </TitleContainer>
      <GridContainer>
        <GridItem $cell={[1, 2]}>
          <InputLabel label={t('pm-withdrawals.payment-option-name')}>
            <Input
              onChange={value => setPaymentMethod({ ...paymentMethod, name: value })}
              value={paymentMethod.name}
              disabled={!hasEditPermission}
            />
          </InputLabel>
        </GridItem>
        <GridItem $cell={[3, 2]}>
          <InputLabel label={t('pm-withdrawals.name-translation-key')}>
            <Input
              onChange={value => setPaymentMethod({ ...paymentMethod, nameTranslationKey: value })}
              value={paymentMethod.nameTranslationKey}
              disabled={!hasEditPermission}
            />
          </InputLabel>
        </GridItem>
        <GridItem $cell={[5, 2]}>
          <InputLabel label={t('pm-withdrawals.fee-value')}>
            <Input
              onChange={value => setFeeAmount({ ...feeAmount, value: parseFloat(value) || 0 })}
              value={feeAmount.value?.toString()}
              disabled={!hasEditPermission}
            />
          </InputLabel>
        </GridItem>
        <GridItem $cell={[7, 2]}>
          <InputLabel label={t('pm-withdrawals.fee-currency-code')}>
            <Input
              onChange={value => setFeeAmount({ ...feeAmount, currencyCode: value })}
              value={feeAmount.currencyCode}
              disabled={!hasEditPermission}
            />
          </InputLabel>
        </GridItem>
        <GridItem $cell={[1, 2]}>
          <InputLabel label={t('pm-withdrawals.description')}>
            <Input
              onChange={value => setPaymentMethod({ ...paymentMethod, description: value })}
              value={paymentMethod.description}
              disabled={!hasEditPermission}
            />
          </InputLabel>
        </GridItem>
        <GridItem $cell={[3, 2]}>
          <InputLabel label={t('pm-withdrawals.description-translation-key')}>
            <Input
              onChange={value => setPaymentMethod({ ...paymentMethod, descriptionTranslationKey: value })}
              value={paymentMethod.descriptionTranslationKey}
              disabled={!hasEditPermission}
            />
          </InputLabel>
        </GridItem>
        <GridItem $cell={[5, 2]}>
          <InputLabel label={t('pm-withdrawals.min-amount-value')}>
            <Input
              onChange={value =>
                setPaymentMethod({
                  ...paymentMethod,
                  minAmount: { ...paymentMethod.minAmount, value: parseFloat(value) || 0 },
                })
              }
              value={paymentMethod.minAmount.value?.toString()}
              disabled={!hasEditPermission}
            />
          </InputLabel>
        </GridItem>
        <GridItem $cell={[7, 2]}>
          <InputLabel label={t('pm-withdrawals.currency-code')}>
            <Input
              onChange={value =>
                setPaymentMethod({
                  ...paymentMethod,
                  currencyCode: value,
                })
              }
              value={paymentMethod.currencyCode}
              disabled={!hasEditPermission}
            />
          </InputLabel>
        </GridItem>
        <GridItem $cell={[1, 2]}>
          <InputLabel label={t('pm-withdrawals.channel-name')}>
            <Select
              options={channelsOptions}
              selected={selectedChannel}
              onSelect={onSelect}
              onSearch={onSearch}
              loading={loading}
              disabled={!hasEditPermission}
            />
          </InputLabel>
        </GridItem>
        <GridItem $cell={[3, 2]}>
          <InputLabel label={t('pm-withdrawals.image-url')}>
            <Input
              onChange={value => setPaymentMethod({ ...paymentMethod, imageUrl: value })}
              value={paymentMethod.imageUrl}
              disabled={!hasEditPermission}
            />
          </InputLabel>
        </GridItem>
        <GridItem
          $align='start'
          $cell={[5, 2]}
        >
          <CheckboxWrapper>
            <Checkbox
              checked={paymentMethod.isEnabled}
              labelPosition={'left'}
              onChange={value => setPaymentMethod({ ...paymentMethod, isEnabled: value })}
              size={22}
              disabled={!hasEditPermission}
            >
              <CheckboxLabel>{t('pm-withdrawals.enabled')}</CheckboxLabel>
            </Checkbox>
          </CheckboxWrapper>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default PaymentOption;
