import { fetchKycApplications } from '@/lib/redux/slices/kyc-applications/actions';
import type { KycApplications } from '@/types';
import { useEffect } from 'react';
import { type RootState, useAppDispatch } from '@/lib/redux';
import { useSelector } from 'react-redux';
import { type FetchKycApplicationsState } from '@/lib/redux/slices/kyc-applications/slice';

export function useKycApplications(customerId: string): { kycInfo: KycApplications; isError: boolean } {
  const kycApplicationsState = useSelector<RootState, FetchKycApplicationsState>(state => state.kycApplicationsState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (kycApplicationsState.key !== customerId) {
      void dispatch(fetchKycApplications({ customerId }));
    }
  }, [customerId, dispatch, kycApplicationsState]);

  return { kycInfo: kycApplicationsState.kycApplications, isError: kycApplicationsState.isError };
}
