import type { CustomerStatusEnum, Customer } from '@/types';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchCustomer, updateCustomerStatus, updateCustomerDetails } from './actions';

export interface CustomerSliceState {
  customer?: Customer | null;
  loading: boolean;
  success?: boolean;
  editLoading?: boolean;
}

const initialState: CustomerSliceState = {
  customer: undefined,
  loading: true,
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
    updateState(state, action) {
      return {
        ...state,
        customer: { ...action.payload },
        success: false,
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchCustomer.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchCustomer.fulfilled, (state, action: PayloadAction<Customer>) => {
      state.loading = false;
      state.customer = action.payload;
    });
    builder.addCase(fetchCustomer.rejected, state => {
      state.loading = false;
      state.customer = null;
    });

    builder.addCase(updateCustomerStatus.fulfilled, (state, action: PayloadAction<CustomerStatusEnum>) => {
      state.customer = {
        ...state.customer,
        status: action.payload,
      };
    });

    builder.addCase(updateCustomerDetails.pending, state => {
      state.editLoading = true;
      state.success = false;
    });
    builder.addCase(updateCustomerDetails.fulfilled, state => {
      state.success = true;
      state.editLoading = false;
    });
    builder.addCase(updateCustomerDetails.rejected, state => {
      state.editLoading = false;
    });
  },
});

export const { reset, updateState } = customerSlice.actions;
