import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CloseAccountButton from '@/components/common/button/implementations/CloseAccountButton';
import BlockButton from '@/components/common/button/implementations/BlockButton';
import UnblockButton from '@/components/common/button/implementations/UnblockButton';
import { PaymentCardActions, PaymentCardStatus } from '@/types/PaymentCards';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import { updatePaymentCard } from '@/lib/redux/slices/payment-cards/actions';
import ActionModal from '@/components/customer/kyc-risk/audit-logs/ActionModal/ActionModal';
import Typography from '@/components/common/typography/Typography';
import { resetUpdateSuccess } from '@/lib/redux/slices/payment-cards/slice';
import InputLabel from '@/components/common/labels/InputLabel';
import styles from '@/components/common/modals/confirmation/ConfirmModal.module.scss';
import Input from '@/components/common/inputs/Input';

export interface ActionsProps {
  customerId: string;
  status: string;
  cardId: string;
  cardNumber?: string;
}

const Actions: React.FC<ActionsProps> = ({ status, cardId, customerId, cardNumber }) => {
  const dispatch = useAppDispatch();
  const { updating, updateSuccess } = useAppSelector((state: RootState) => state.paymentCardsState);
  const [openActionsModal, setOpenActionsModal] = useState(false);
  const [action, setAction] = useState('');
  const [comment, setComment] = useState('');
  const { t } = useTranslation(['customerOverview', 'global']);

  const isCloseButtonNotShowing = status === PaymentCardStatus.Cancelled || status === PaymentCardStatus.Closed;

  const modalActions = {
    BLOCK: 'block',
    CLOSE: 'close',
    UNBLOCK: 'unblock',
  };

  useEffect(() => {
    if (updateSuccess) {
      handleCloseActionModal();
      dispatch(resetUpdateSuccess());
      setComment('');
    }
  }, [updateSuccess, dispatch]);

  const handleOpenModal = (action: string): void => {
    setOpenActionsModal(true);
    setAction(action);
  };

  const handleCloseActionModal = (): void => {
    setOpenActionsModal(false);
    setAction('');
  };

  const handleConfirm = (): void => {
    let actionStatus = '';

    switch (action) {
      case modalActions.CLOSE:
        actionStatus = PaymentCardActions.CancelledByCs;
        break;
      case modalActions.UNBLOCK:
        actionStatus = PaymentCardActions.Active;
        break;
      case modalActions.BLOCK:
        actionStatus = PaymentCardActions.BlockedByCs;
        break;
    }

    if (comment && comment.trim().length > 0) {
      void dispatch(updatePaymentCard({ customerId, cardId, status: actionStatus, comment }));
    }
  };

  return (
    <>
      {status === PaymentCardStatus.Blocked && <UnblockButton onClick={() => handleOpenModal(modalActions.UNBLOCK)} />}
      {status === PaymentCardStatus.Active && <BlockButton onClick={() => handleOpenModal(modalActions.BLOCK)} />}
      {!isCloseButtonNotShowing && <CloseAccountButton onClick={() => handleOpenModal(modalActions.CLOSE)} />}
      {openActionsModal && (
        <ActionModal
          disabledButton={updating || comment.trim().length < 1}
          confirmButtonLabel={t('confirm-modal.yes', { ns: 'global' })}
          closeButtonLabel={t('confirm-modal.no', { ns: 'global' })}
          onClose={handleCloseActionModal}
          onConfirm={handleConfirm}
        >
          <Typography variant='po'>
            {t('widgets.cards.change-status-message', {
              action,
              cardNumber,
            })}
          </Typography>
          <InputLabel
            label={t('confirm-modal.insert-comment-message', { ns: 'global' })}
            className={styles.comment}
          >
            <Input
              value={comment}
              onChange={(newValue: string) => setComment(newValue)}
              required={true}
            />
          </InputLabel>
        </ActionModal>
      )}
    </>
  );
};

export default Actions;
