import { useState, useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ToggleButton } from 'k8-web-lib-tmp';

import Button from '@/components/common/button/Button';
import { ComplianceDocs } from '@/components/configurations';
import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle';
import CountryTranslation from '@/components/configurations/country-translation/CountryTranslation';
import CountryParameters from '@/components/configurations/country-parameters/CountryParameters';
import { Permissions, type ViewMode } from '@/types';
import { usePermissions } from '@/utils/hooks/usePermissions';
import { useAppDispatch, useAppSelector } from '@/lib/redux';
import ConfirmationModal from '@/components/common/modals/confirmation/ConfirmModal';
import { countryEditIsEnabled } from '@/lib/redux/slices/country/actions';

import styles from './CountryInformation.module.scss';

const getViewMode = (isNew: boolean, hasEditPermission: boolean = false): ViewMode => {
  if (isNew) {
    return 'add';
  }

  if (hasEditPermission) {
    return 'edit';
  }

  return 'read';
};

const CountryInformation = (): JSX.Element => {
  const { t } = useTranslation('configurations');
  useDocumentTitle(t('new-country.page-title'));
  const navigate = useNavigate();

  const { country, isLoading } = useAppSelector(state => state.country);
  const dispatch = useAppDispatch();

  const hasEditPermission = usePermissions(Permissions.countryConfigEdit);
  const isNew = country.countryCode.length === 0;
  const viewMode = getViewMode(isNew, hasEditPermission);

  const [countryIsEnabled, setCountryIsEnabled] = useState<boolean>(country.isEnabled);
  const [confirmModalShow, setConfirmModalShow] = useState<boolean>(false);
  const handleCancel = useCallback((): void => {
    navigate('/configurations/countries');
  }, [navigate]);

  useEffect(() => {
    if (hasEditPermission === false && isNew) {
      handleCancel();
    }
  }, [hasEditPermission, isNew, handleCancel]);

  const onChangeEnable = (): void => {
    setConfirmModalShow(true);
  };

  const onToggleConfirm = (): void => {
    setConfirmModalShow(false);
    const newValue = !countryIsEnabled;
    setCountryIsEnabled(newValue);
    void dispatch(
      countryEditIsEnabled({
        isEnabled: newValue,
        countryCode: country.countryCode.toUpperCase(),
      }),
    );
  };

  const renderCountryEnabling = (): JSX.Element => {
    if (isLoading) return <></>;

    const isReadOnly = viewMode === 'add' || viewMode === 'read';
    return (
      <div className={styles.topRight + (isReadOnly ? ' ' + styles.readOnly : '')}>
        <ToggleButton
          disabled={isReadOnly}
          checked={countryIsEnabled}
          onChange={onChangeEnable}
          labelPosition='left'
        >
          <span>{t('toggle-enabled-title')}</span>
        </ToggleButton>
        {!isReadOnly && confirmModalShow && (
          <ConfirmationModal
            title={
              countryIsEnabled
                ? t('toggle-disable-confirm', { field: country.countryName })
                : t('toggle-enable-confirm', { field: country.countryName })
            }
            onConfirm={onToggleConfirm}
            onDeny={() => setConfirmModalShow(false)}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      {renderCountryEnabling()}
      <CountryParameters mode={viewMode} />
      <CountryTranslation mode={viewMode} />
      <ComplianceDocs mode={viewMode} />
      <div className={styles.buttons}>
        <Button
          onClick={handleCancel}
          variant='secondary'
          textTransform='uppercase'
        >
          {t('new-country.button-cancel')}
        </Button>
      </div>
    </div>
  );
};

export { CountryInformation };
