import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { type RootState, useAppSelector, useAppDispatch } from '@/lib/redux';
import useItemsPerPage from '@/utils/hooks/useItemsPerPage';
import { resetFeatureFlag } from '@/lib/redux/slices/feature-flags/slice';
import { addFeatureFlag } from '@/lib/redux/slices/feature-flags/actions';
import { type FeatureFlag } from '@/types/FeatureFlag';
import ActionModal from '@/components/customer/kyc-risk/audit-logs/ActionModal/ActionModal';
import InputLabel from '@/components/common/labels/InputLabel';
import Input from '@/components/common/inputs/Input';
import styles from './FeatureModal.module.scss';

const initialState: FeatureFlag = {
  id: '',
  description: '',
  enabledForAll: false,
};

const FeatureModal = ({ open, onClose }: { open: boolean; onClose: () => void }): JSX.Element => {
  const { t } = useTranslation(['configurations']);

  const dispatch = useAppDispatch();

  const { successfulFlagAddition, isLoadingAddFlag } = useAppSelector((state: RootState) => state.featureFlags);
  const [itemsPerPage] = useItemsPerPage();

  const [featureFlag, setFeatureFlag] = useState<FeatureFlag>(initialState);

  const handleClose = useCallback(() => {
    onClose();
    setFeatureFlag(initialState);
  }, [onClose]);

  useEffect(() => {
    if (successfulFlagAddition) {
      handleClose();
      dispatch(resetFeatureFlag());
    }
  }, [dispatch, successfulFlagAddition, handleClose, featureFlag, itemsPerPage]);

  const handleConfirm = (): void => {
    void dispatch(addFeatureFlag({ body: { ...featureFlag } }));
  };

  const handleNameChange = (name: string): void => setFeatureFlag(flag => ({ ...flag, id: name }));

  const handleDescriptionChange = (des: string): void => setFeatureFlag(flag => ({ ...flag, description: des }));

  return (
    <>
      {open && (
        <ActionModal
          title={t('feature-flags.add-feature.title')}
          titleVariant='po'
          disabledButton={featureFlag.id.trim().length === 0 || isLoadingAddFlag}
          confirmButtonLabel={t('feature-flags.add-feature.create')}
          closeButtonLabel={t('confirm-modal.cancel', { ns: 'global' })}
          onConfirm={handleConfirm}
          onClose={handleClose}
        >
          <div className={styles.inputCard}>
            <InputLabel label={t('feature-flags.add-feature.input-feature-name')}>
              <Input
                value={featureFlag.id}
                onChange={handleNameChange}
              />
            </InputLabel>
            <InputLabel label={t('feature-flags.add-feature.input-description')}>
              <Input
                value={featureFlag.description}
                onChange={handleDescriptionChange}
              />
            </InputLabel>
          </div>
        </ActionModal>
      )}
    </>
  );
};

export default FeatureModal;
