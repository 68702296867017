import { type CustomerAddressDoc } from '@/types';
import { createSlice } from '@reduxjs/toolkit';
import { fetchCustomerAddressDocuments } from './actions';

export interface CustomerAddressDocumentsSliceState {
  loading: boolean;
  addressDocs?: CustomerAddressDoc | null;
  isError: boolean;
}

const initialState: CustomerAddressDocumentsSliceState = {
  loading: true,
  isError: false,
};

export const customerAddressDocumentsSlice = createSlice({
  name: 'customer-address-documents',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCustomerAddressDocuments.pending, state => {
      state.loading = true;
      state.isError = false;
    });
    builder.addCase(fetchCustomerAddressDocuments.fulfilled, (state, action) => {
      state.loading = false;
      state.addressDocs = action.payload ? action.payload : null;
    });
    builder.addCase(fetchCustomerAddressDocuments.rejected, state => {
      state.loading = false;
      state.addressDocs = null;
      state.isError = true;
    });
  },
});
