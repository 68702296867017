import { createAsyncThunk } from '@reduxjs/toolkit';

import authAxios from '@/auth/axios';
import { errorToast } from '@/utils/toastMessage';
import { type LimitLevel } from '@/types/LimitLevel';

interface LimitLevelsResponse {
  data: {
    data: LimitLevel[];
  };
}

export const getLimitLevels = createAsyncThunk('limits.getLimitLevels', async (_, { rejectWithValue }) => {
  try {
    const response = await authAxios.get<LimitLevelsResponse>('/limit-configurations/limit-levels');

    return response.data.data.data;
  } catch (err: any) {
    errorToast();
    return rejectWithValue(err);
  }
});
