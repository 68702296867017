import { useCallback, type FC } from 'react';

import { useTranslation } from 'react-i18next';

import LinkButton from '@/components/common/button/LinkButton';
import StyledIconButton from '@/components/common/button/StyledIconButton';
import Table from '@/components/common/tables/Table';
import EditIcon from '@/components/icons/EditIcon';
// import RelateIcon from '@/components/icons/RelateIcon';
import { openModal } from '@/lib/redux/slices/payment-method-groups-topup/slice';
import { useAppDispatch } from '@/lib/redux';
import styles from '@/pages/configurations/countries-configurations/CountriesConfigurations.module.scss';
import { type PaymentMethodGroup } from '@/types/PaymentMethodGroup';

type Props = Readonly<{
  paymentMethodsGroup: PaymentMethodGroup;
  isReadOnly: boolean;
  isNoGroupMethods?: boolean;
  setActiveGroupId: (groupId: string) => void;
}>;

const PaymentMethodsGroupsTableRow: FC<Props> = ({
  paymentMethodsGroup,
  isReadOnly,
  isNoGroupMethods = false,
  setActiveGroupId,
}) => {
  const { t } = useTranslation('configurations');
  const dispatch = useAppDispatch();

  const handleClick = useCallback((): void => {
    setActiveGroupId(paymentMethodsGroup.id || '');
  }, [setActiveGroupId, paymentMethodsGroup.id]);

  const handleOpenModal = useCallback(() => {
    dispatch(openModal({ mode: 'opened-edit', data: paymentMethodsGroup }));
  }, [dispatch, paymentMethodsGroup]);

  return (
    <Table.Row>
      <Table.Cell>
        <LinkButton onClick={handleClick}>{paymentMethodsGroup.nameTranslationKey}</LinkButton>
      </Table.Cell>
      <Table.Cell>{paymentMethodsGroup.id}</Table.Cell>
      <Table.Cell>{paymentMethodsGroup.order}</Table.Cell>
      <Table.Cell>{paymentMethodsGroup.reason}</Table.Cell>
      <Table.Cell>
        {paymentMethodsGroup.isEnabled ? t('payment-method-topup.yes') : t('payment-method-topup.no')}
      </Table.Cell>
      {!isReadOnly && (
        <Table.Cell>
          {!isNoGroupMethods && (
            <div className={styles.actions}>
              <StyledIconButton
                onClick={handleOpenModal}
                icon={<EditIcon color={isNoGroupMethods ? 'currentColor' : undefined} />}
                disabled={isNoGroupMethods}
                data-testid='edit-action'
              />
              {/*
          Add delete action once the ticket is added to a Sprint
          <RelateIcon color='lightgrey' />
          */}
            </div>
          )}
        </Table.Cell>
      )}
    </Table.Row>
  );
};

export default PaymentMethodsGroupsTableRow;
