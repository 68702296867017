import React, { useState, useMemo } from 'react';
import InputLabel from '@/components/common/labels/InputLabel';
import NumberInput from '@/components/common/inputs/NumberInput';
import Input from '@/components/common/inputs/Input';
import { useTranslation } from 'react-i18next';
import styles from '@/components/customer/transaction-history/transaction-actions/TransactionActions.module.scss';
import type { NumberFormatValues } from 'react-number-format';
import { ModalActions } from '@/components/customer/kyc-risk/audit-logs/ActionModal/Styles';
import Button from '@/components/common/button/Button';
import { type Option } from '@/utils/interfaces';
import Dropdown from '@/components/common/filters/Dropdown';

enum ChargebackType {
  Fraud = 'fraud',
  Controversy = 'controversy',
}

enum ReasonValues {
  PRODUCT_NOT_DELIVERED = 'PRODUCT NOT DELIVERED',
  PURCHASE_CANCELLATION = 'PURCHASE CANCELLATION',
  SERVICE_NOT_PERFORMED = 'SERVICE NOT PERFORMED',
  OTHER = 'OTHER',
  PHYSICAL_PAYMENT = 'PHYSICAL PAYMENT',
  VIRTUAL_PAYMENT = 'VIRTUAL PAYMENT',
}

export interface ActionInputProps {
  disabledButton: boolean;
  onConfirm: (amount: number, reason: string) => void;
  onClose: () => void;
  inputLabel?: string;
  isAllowed?: (values: NumberFormatValues) => boolean;
  hint?: string;
}

const ActionCardChargeback: React.FC<ActionInputProps> = ({
  disabledButton,
  onConfirm,
  onClose,
  inputLabel,
  isAllowed,
  hint,
}) => {
  const { t } = useTranslation(['customerOverview', 'global']);
  const [requestedAmountNumber, setRequestedAmountNumber] = useState(0);
  const [requestedAmountString, setRequestedAmountString] = useState('');
  const [reason, setReason] = useState<Option>({ text: '', value: '' });
  const [chargebackType, setChargebackType] = useState<Option>({ text: '', value: '' });
  const [comment, setComment] = useState('');
  const isSubmitEnabled = false;

  const handleAmount = (values: NumberFormatValues): void => {
    const { value, floatValue } = values;
    setRequestedAmountNumber(floatValue ?? 0);
    setRequestedAmountString(value);
  };

  const handleComment = (updatedReason: string): void => {
    setComment(updatedReason);
  };

  const handleCurrencySelect = (opt: Option): void => {
    setChargebackType(opt);
    setReason({ text: '', value: '' });
  };

  const handleReasonSelect = (opt: Option): void => {
    setReason(opt);
  };

  const chargebackTypeOptions = [
    { text: t('widgets.transaction-history.modal-input-type-fraud'), value: ChargebackType.Fraud },
    { text: t('widgets.transaction-history.modal-input-type-controversy'), value: ChargebackType.Controversy },
  ];

  const reasonOptions = useMemo(() => {
    return [
      ...(ChargebackType.Fraud === chargebackType.value
        ? [
            { text: ReasonValues.VIRTUAL_PAYMENT, value: ReasonValues.VIRTUAL_PAYMENT },
            { text: ReasonValues.PHYSICAL_PAYMENT, value: ReasonValues.PHYSICAL_PAYMENT },
          ]
        : []),
      ...(ChargebackType.Controversy === chargebackType.value
        ? [
            { text: ReasonValues.PRODUCT_NOT_DELIVERED, value: ReasonValues.PRODUCT_NOT_DELIVERED },
            { text: ReasonValues.PURCHASE_CANCELLATION, value: ReasonValues.PURCHASE_CANCELLATION },
            { text: ReasonValues.SERVICE_NOT_PERFORMED, value: ReasonValues.SERVICE_NOT_PERFORMED },
            { text: ReasonValues.OTHER, value: ReasonValues.OTHER },
          ]
        : []),
    ];
  }, [chargebackType.value]);

  return (
    <div>
      <div className={styles.contentWrapper}>
        <InputLabel label={t('widgets.transaction-history.modal-input-type')}>
          <Dropdown
            options={chargebackTypeOptions}
            onSelect={handleCurrencySelect}
            selectedOption={chargebackType}
            setSelectedOption={() => {}}
          />
        </InputLabel>
        <InputLabel label={inputLabel ?? t('widgets.transaction-history.modal-input-amount')}>
          <NumberInput
            value={requestedAmountString}
            onChange={handleAmount}
            isAllowed={
              isAllowed ??
              (() => {
                return true;
              })
            }
            hint={hint}
          />
        </InputLabel>
        <InputLabel label={t('widgets.transaction-history.modal-input-reason')}>
          <Dropdown
            options={reasonOptions}
            onSelect={handleReasonSelect}
            selectedOption={reason}
            setSelectedOption={() => {}}
          />
        </InputLabel>
        <InputLabel label={t('widgets.transaction-history.modal-input-comment')}>
          <Input
            value={comment}
            onChange={handleComment}
            maxLength={200}
          />
        </InputLabel>
      </div>
      <ModalActions>
        <Button
          disabled={disabledButton}
          variant='secondary'
          onClick={onClose}
          textTransform='uppercase'
        >
          {t('confirm-modal.cancel', { ns: 'global' })}
        </Button>
        <Button
          disabled={!isSubmitEnabled || disabledButton}
          onClick={() => onConfirm(requestedAmountNumber, comment)}
          textTransform='uppercase'
        >
          {t('confirm-modal.confirm', { ns: 'global' })}
        </Button>
      </ModalActions>
    </div>
  );
};

export default ActionCardChargeback;
