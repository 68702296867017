import { useRef, useState, type ChangeEvent } from 'react';

import Input from '@/components/common/inputs/Input';
import UploadIcon from '@/components/icons/UploadIcon';
import styles from '@/components/common/inputs/FileUploadInput.module.scss';
import { extraLightGrey } from '@/utils/colors';

export interface FileUploadInputProps {
  accept?: string;
  onChange: (file: File) => void;
  disabled?: boolean;
}

export default function FileUploadInput({
  accept,
  onChange,
  disabled = false,
}: Readonly<FileUploadInputProps>): JSX.Element {
  const [fileName, setFileName] = useState('');

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e?.target?.files) {
      const selectedFile = e.target.files[0];
      setFileName(selectedFile.name);
      onChange(selectedFile);
    }
  };

  const handleIconClick = (): void => {
    fileInputRef?.current?.click();
  };

  return (
    <div className={styles.content}>
      <div className={styles.input}>
        <Input
          disabled
          value={fileName}
        />
      </div>
      <div
        className={styles.icon}
        onKeyDown={handleIconClick}
        onClick={handleIconClick}
      >
        <input
          type='file'
          accept={accept}
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
          disabled={disabled}
        />
        <UploadIcon color={disabled ? extraLightGrey.cssColor : undefined} />
      </div>
    </div>
  );
}
