import type { NumberFormatValues } from 'react-number-format';
import { RuntimeEnvironments } from '@/utils/constants';

type EnumValueType = string | number | symbol;

export function getEnumKeyFromValue<T extends Record<string, EnumValueType>>(
  enumRef: T,
  enumValue: EnumValueType,
): string {
  const enumKey = Object.keys(enumRef)[Object.values(enumRef).indexOf(enumValue)];

  return enumKey;
}

export function parseEnumValue<T extends Record<string, EnumValueType>>(
  enumObj: T,
  value: string | undefined,
): T[keyof T] | undefined {
  if (!value?.trim()) {
    return undefined;
  }

  const enumValues = Object.values(enumObj) as unknown as string[];
  if (enumValues.includes(value)) {
    return value as T[keyof T];
  }

  return undefined;
}

export function isKeyOfEnum<T extends Record<string, EnumValueType>>(
  enumRef: T,
  enumKey: EnumValueType,
): enumKey is keyof typeof enumRef {
  return enumKey in enumRef;
}

type AnyObject = Record<string, any>;

export const isObject = (obj: any): obj is AnyObject => {
  return obj !== null && typeof obj === 'object';
};

export const objectDeepEquality = <T extends AnyObject>(a: T, b: T): boolean => {
  if (a === b) return true;

  if (!isObject(a) || !isObject(b)) {
    return false;
  }

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  if (keysA.length !== keysB.length) return false;

  for (const key of keysA) {
    if (!keysB.includes(key) || !objectDeepEquality(a[key], b[key] as T)) return false;
  }

  return true;
};

export const truncateText = (data: string | undefined, maxLength: number): string | undefined => {
  if (data === undefined) {
    return undefined;
  }

  let truncated = data.substring(0, maxLength);

  if (truncated.length === maxLength) {
    truncated = truncated.concat('...');
  }

  return truncated;
};

type ObjType = Record<string, any>;

export function pick<T extends ObjType>(obj: T, keys: Array<keyof T>): Partial<T> {
  return keys.reduce<Partial<T>>((acc, key) => {
    if (key in obj) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}

export function formatParameterizedString(url: string, parameters: Record<string, string>): string {
  if (parameters !== null) {
    for (const key in parameters) {
      url = url.replaceAll(`{${key}}`, parameters[key]);
    }
  }

  return url;
}

export function toQueryParams(params: Record<string, any>): string {
  const query = Object.entries(params)
    .filter(([_, value]) => value !== null && value !== undefined)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
    .join('&');
  return query ? `?${query}` : '';
}

export function extractParamsFromResponse(
  response: any,
  pathsInResponse: Record<string, string[]>,
): Record<string, string> | undefined {
  const result: Record<string, string> = {};
  const keys = Object.keys(pathsInResponse);
  for (const key of keys) {
    for (const searchPath of pathsInResponse[key]) {
      let value = response;

      for (const prop of searchPath.split('.')) {
        if (!value) {
          break;
        }
        value = value[prop];
      }

      result[key] = value;

      if (value !== undefined) {
        break;
      }
    }
    if (!result[key]) {
      return undefined;
    }
  }

  return result;
}

export function replaceStringValuesInObject(obj: any, parameters: Record<string, string>): any {
  if (Array.isArray(obj)) {
    return obj.map(item => replaceStringValuesInObject(item, parameters));
  }

  if (typeof obj === 'object' && obj !== null) {
    const newObj: any = {};
    for (const key in obj) {
      newObj[key] = replaceStringValuesInObject(obj[key], parameters);
    }
    return newObj;
  }

  if (typeof obj === 'string' && parameters[obj]) {
    return parameters[obj];
  }

  return obj;
}

export function isAmountAllowed(values: NumberFormatValues, maxValue: number): boolean {
  const { floatValue } = values;
  return floatValue === undefined || floatValue <= maxValue;
}

export const parseNumberOrDefault = (value: string, defaultValue: number): number => {
  const parsedValue = Number(value);
  if (isNaN(parsedValue)) {
    return defaultValue;
  }
  return parsedValue;
};

export function splitCapitalizedWords(input: string): string {
  return input.replace(/([A-Z])/g, ' $1').trim();
}

export const getMainPath = (value: string): string => value.match(/\/?(\w+)\/?/)?.[1] || '';

export const isProdEnvironment = (): boolean => process.env.REACT_APP_RUNTIME_ENV === RuntimeEnvironments.Production;

export const parseJSON = <T>(jsonString: string = ''): T => {
  try {
    const parsed = JSON.parse(jsonString);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return typeof parsed === 'object' && parsed !== null ? parsed : ({} as T);
  } catch {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return {} as T;
  }
};
