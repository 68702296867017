import { useEffect, type FC } from 'react';

import { useAppDispatch } from '@/lib/redux';
import { getLimitLevels } from '@/lib/redux/slices/limits/actions';
import { reset } from '@/lib/redux/slices/limits/slice';
import LimitLevels from '@/components/configurations/limits/LimitLevels';
import MoneyIn from '@/components/configurations/limits/MoneyIn';
import FeatureFlagGuard from '@/components/feature-flag/FeatureFlagGuard';
import { FeatureFlagKeys, featureFlagsData } from '@/types';

import { LimitsContainer } from './styles';

const Limits: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (featureFlagsData[FeatureFlagKeys.CountryConfLimits]) {
      void dispatch(getLimitLevels());
    }

    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  return (
    <LimitsContainer>
      <FeatureFlagGuard featureFlag={FeatureFlagKeys.CountryConfLimits}>
        <>
          <LimitLevels />
          <MoneyIn />
        </>
      </FeatureFlagGuard>
    </LimitsContainer>
  );
};

export default Limits;
