import { type Account, type Accounts, CurrencyEnum } from '@/types';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchAccounts } from './actions';

export interface AccountsSliceState {
  accounts?: Accounts | null;
  loading: boolean;
  isError: boolean;
}

const initialState: AccountsSliceState = {
  accounts: undefined,
  loading: true,
  isError: false,
};

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAccounts.pending, state => {
      state.loading = true;
      state.isError = false;
    });
    builder.addCase(fetchAccounts.fulfilled, (state, action: PayloadAction<Account[]>) => {
      state.loading = false;

      const primaryAccount = action.payload.find(account => account.currencyCode !== CurrencyEnum.USD);
      const secondaryAccount = action.payload.find(account => account.currencyCode === CurrencyEnum.USD);
      state.accounts = {
        primaryAccount,
        secondaryAccount,
      };
    });
    builder.addCase(fetchAccounts.rejected, state => {
      state.loading = false;
      state.accounts = null;
      state.isError = true;
    });
  },
});

export const { reset } = accountsSlice.actions;
