import { useEffect } from 'react';

import { useAppSelector, useAppDispatch } from '@/lib/redux';
import { getUserPermissions } from '@/lib/redux/slices/user-permissions/actions';
import { type Permissions } from '@/types';

export function usePermissions(permission: Permissions): boolean | undefined {
  const userPermission = useAppSelector(state => state.userPermissions.permissions[permission]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userPermission === undefined) {
      void dispatch(getUserPermissions({ permission }));
    }
  }, [permission, userPermission, dispatch]);

  return userPermission?.value;
}
