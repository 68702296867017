import { ToggleButton } from 'k8-web-lib-tmp';

import { Permissions } from '@/types';
import Typography from '@/components/common/typography/Typography';
import styles from './AccordionTitleWithToggle.module.scss';
import { usePermissions } from '@/utils/hooks/usePermissions';

interface Props {
  title: string;
  checked: boolean;
  onChange: () => void;
}

const AccordionTitleWithToggle = ({ title, checked = false, onChange }: Readonly<Props>): JSX.Element => {
  const hasCountryEditPermission = usePermissions(Permissions.countryConfigEdit);
  return (
    <div className={styles.title}>
      <ToggleButton
        checked={checked}
        onChange={onChange}
        disabled={!hasCountryEditPermission}
      />
      <Typography variant='p2MediumTight'> {title}</Typography>
    </div>
  );
};

export default AccordionTitleWithToggle;
