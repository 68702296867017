import React from 'react';
import { useTranslation } from 'react-i18next';

import LimitsWidget from '@/components/customer/limits-widget/LimitsWidget';

import styles from './LimitsTab.module.scss';
import { type RootState, useAppSelector } from '@/lib/redux';
import { normalGrey } from '@/utils/colors';
import Typography from '@/components/common/typography/Typography';
import { CountriesEnum } from '@/types/Countries';
import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle';

export interface LimitConfig {
  title: string;
  daily?: number;
  weekly?: number;
  monthly?: number;
  perTrn?: number;
  isPrimary?: boolean;
}

export const limitsMoneyIn: Record<CountriesEnum, LimitConfig[]> = {
  [CountriesEnum.CHL]: [
    { title: 'Payment to Customer', daily: 10000, weekly: 10000, monthly: 10000, perTrn: 10000, isPrimary: true },
    { title: 'Peer to Peer Transfer', daily: 1000, weekly: 2000, monthly: 4000, perTrn: 500, isPrimary: true },
    { title: 'Topup', isPrimary: true },
    { title: 'Acuenta', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 1000 },
    { title: 'Banco de Chile', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 5000 },
    { title: 'Banco Estado', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 5000 },
    { title: 'Banco Falabella', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 5000 },
    { title: 'Banco Santander', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 5000 },
    { title: 'BCI', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 5000 },
    { title: 'Express lider', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 1000 },
    { title: 'Khipu', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 5000 },
    { title: 'Lider', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 1000 },
    { title: 'Servifacil', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 1000 },
    { title: 'Webpay for Mastercard', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 5000 },
    { title: 'Webpay for RedCompra', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 5000 },
    { title: 'Webpay for Visa', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 5000 },
  ],
  [CountriesEnum.PER]: [
    { title: 'Payment to Customer', daily: 10000, weekly: 10000, monthly: 10000, perTrn: 10000, isPrimary: true },
    { title: 'Peer to Peer Transfer', daily: 1000, weekly: 2000, monthly: 4000, perTrn: 500, isPrimary: true },
    { title: 'Topup', isPrimary: true },
    { title: 'BBVA', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 4500 },
    { title: 'BCP', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 4500 },
    { title: 'Interbank', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 4500 },
    { title: 'Kasnet', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 900 },
    { title: 'Pago Efectivo', daily: 11000, weekly: 17000, monthly: 33000, perTrn: 400 },
    { title: 'Scotiabank', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 500 },
  ],
  [CountriesEnum.ARG]: [
    { title: 'Payment to Customer', isPrimary: true },
    { title: 'Peer to Peer Transfer', daily: 1000, weekly: 2000, monthly: 4000, perTrn: 500, isPrimary: true },
    { title: 'Topup', isPrimary: true },
    { title: 'Mercado Pago', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 5000 },
    { title: 'Banco Galicia', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 5000 },
    { title: 'Banco BBVA', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 5000 },
    { title: 'Banco Santander', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 5000 },
    { title: 'Banco Macro', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 5000 },
    { title: 'Banco Nación', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 5000 },
    { title: 'Banco ICBC', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 5000 },
    { title: 'Banco Ciudad', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 5000 },
    { title: 'Banco Supervielle', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 5000 },
    { title: 'Banco HSBC', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 5000 },
    { title: 'Khipu', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 5000 },
    { title: 'Mobbex - Mastercard', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 5000 },
    { title: 'Mobbex - Visa', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 5000 },
    { title: 'Pago Fácil', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 1000 },
    { title: 'Rapipago', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 1000 },
  ],
  [CountriesEnum.COL]: [
    { title: 'Payment to Customer', isPrimary: true },
    { title: 'Peer to Peer Transfer', daily: 1000, weekly: 2000, monthly: 4000, perTrn: 500, isPrimary: true },
    { title: 'Topup', isPrimary: true },
    { title: 'PSE', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 1000 },
    { title: 'Efecty', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 900 },
    { title: 'Credit/Debit card', daily: 2500, weekly: 4000, monthly: 7000, perTrn: 1000 },
  ],
  [CountriesEnum.MEX]: [
    { title: 'Payment to Customer', isPrimary: true },
    { title: 'Peer to Peer Transfer', daily: 1000, weekly: 2000, monthly: 4000, perTrn: 500, isPrimary: true },
    { title: 'Topup', isPrimary: true },
    { title: 'SPEI', daily: 10000, weekly: 15000, monthly: 25000, perTrn: 5000 },
    { title: 'Credit/Debit card', daily: 2500, weekly: 4000, monthly: 7000, perTrn: 850 },
    { title: 'BBVA', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 1000 },
    { title: 'Bodega Aurrerá', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 1000 },
    { title: '7 Eleven', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 750 },
    { title: 'Walmart', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 1000 },
    { title: 'Walmart Express', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 500 },
    { title: 'Sam\'s Club', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 1000 },
    { title: 'Soriana', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 500 },
    { title: 'Farmacias Dr. Ahorro', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 1000 },
    { title: 'Farmacias La Más Barata', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 500 },
    { title: 'Farmacias Roma', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 500 },
    { title: 'Circle K', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 500 },
    { title: 'Extra', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 300 },
    { title: 'City Club', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 500 },
    { title: 'Súper City', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 500 },
    { title: 'Calimax', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 250 },
    { title: 'Súper del Norte', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 500 },
  ],
  [CountriesEnum.ECU]: [],
};

export const limitsMoneyOut: Record<string, LimitConfig[]> = {
  [CountriesEnum.CHL]: [
    { title: 'Payment from Customer', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 1000, isPrimary: true },
    { title: 'Peer to Peer Transfer', daily: 1000, weekly: 2000, monthly: 4000, perTrn: 500, isPrimary: true },
    { title: 'Prepaid Card ATM Withdrawal', daily: 1000, weekly: 2000, monthly: 4000, perTrn: 500, isPrimary: true },
    { title: 'Prepaid Card POS Spend', daily: 1000, weekly: 2000, monthly: 4000, perTrn: 500, isPrimary: true },
    { title: 'Prepaid Card Virtual Spend', daily: 1000, weekly: 2000, monthly: 4000, perTrn: 500, isPrimary: true },
    { title: 'Withdrawal', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 500, isPrimary: true },
  ],
  [CountriesEnum.PER]: [
    { title: 'Payment from Customer', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 1000, isPrimary: true },
    { title: 'Peer to Peer Transfer', daily: 1000, weekly: 2000, monthly: 4000, perTrn: 500, isPrimary: true },
    { title: 'Prepaid Card ATM Withdrawal', daily: 1000, weekly: 2000, monthly: 4000, perTrn: 500, isPrimary: true },
    { title: 'Prepaid Card POS Spend', daily: 1000, weekly: 2000, monthly: 4000, perTrn: 500, isPrimary: true },
    { title: 'Prepaid Card Virtual Spend', daily: 1000, weekly: 2000, monthly: 4000, perTrn: 500, isPrimary: true },
    { title: 'Withdrawal', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 500, isPrimary: true },
  ],
  [CountriesEnum.ARG]: [
    { title: 'Payment from Customer', daily: 50000, perTrn: 10000, isPrimary: true },
    { title: 'Peer to Peer Transfer', daily: 1000, weekly: 2000, monthly: 4000, perTrn: 500, isPrimary: true },
    { title: 'Withdrawal', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 500, isPrimary: true },
  ],
  [CountriesEnum.COL]: [
    { title: 'Payment from Customer', daily: 50000, perTrn: 10000, isPrimary: true },
    { title: 'Peer to Peer Transfer', daily: 1000, weekly: 2000, monthly: 4000, perTrn: 500, isPrimary: true },
    { title: 'Withdrawal', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 500, isPrimary: true },
  ],
  [CountriesEnum.MEX]: [
    { title: 'Payment from Customer', daily: 50000, perTrn: 10000, isPrimary: true },
    { title: 'Peer to Peer Transfer', daily: 1000, weekly: 2000, monthly: 4000, perTrn: 500, isPrimary: true },
    { title: 'Withdrawal', daily: 1000, weekly: 2000, monthly: 8000, perTrn: 500, isPrimary: true },
  ],
  [CountriesEnum.ECU]: [],
};

function LimitsTab(): React.JSX.Element {
  const { t } = useTranslation('customerOverview');

  const customer = useAppSelector((state: RootState) => state.customer.customer);
  let limitsIn, limitsOut;
  if (customer?.countryCode) {
    limitsIn = limitsMoneyIn[customer.countryCode as keyof typeof CountriesEnum];
    limitsOut = limitsMoneyOut[customer.countryCode as keyof typeof CountriesEnum];
  }
  useDocumentTitle(
    customer
      ? t('navigation-tabs.limits-page-title', { customerFullName: `${customer.firstName} ${customer.lastName}` })
      : '',
  );

  return limitsIn && limitsOut ? (
    <div className={styles['limits-tab']}>
      <LimitsWidget
        limits={limitsIn}
        title={t('widgets.limits.money-in')}
      />
      <LimitsWidget
        limits={limitsOut}
        title={t('widgets.limits.money-out')}
      />
    </div>
  ) : (
    <div className={styles['no-limits-available-label']}>
      <Typography
        color={normalGrey.cssColor}
        variant='h2'
      >
        {t('widgets.limits.missing-limits-message')}
      </Typography>
    </div>
  );
}

export default LimitsTab;
