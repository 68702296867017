const defaultDateTimeFormattingOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
  hourCycle: 'h12',
};

const onlyDateFormattingOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

const defaultLocale = 'en-GB';

const defaultDateTimeFormatter = new Intl.DateTimeFormat(defaultLocale, defaultDateTimeFormattingOptions);

function toUtcDate(date: Date): Date {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds(),
  );
}

function toUtcDateString(date: Date | string | undefined, options: Intl.DateTimeFormatOptions | null = null): string {
  if (date === undefined) {
    return '';
  }

  const utcDate = toUtcDate(typeof date === 'string' ? new Date(date) : date);

  if (options === null) {
    return defaultDateTimeFormatter.format(utcDate).replaceAll(',', '');
  }

  return new Intl.DateTimeFormat(defaultLocale, options).format(utcDate).replaceAll(',', '');
}

function getValidDate(timestamp: string): Date | undefined {
  let date;
  if ((new Date(timestamp)).getTime() > 0) {
    date = new Date(timestamp);
  } else {
    date = new Date(+timestamp);
  }

  if (date.toString() !== 'Invalid Date') {
    return date;
  }

  return undefined;
}

export { defaultDateTimeFormatter, onlyDateFormattingOptions, toUtcDate, toUtcDateString, getValidDate };
