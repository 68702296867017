import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import { type TransactionBA } from '@/types';
import { type TransactionHistoryBAResponse, fetchTransactions } from './actions';

interface TransactionHistoryBAState {
  transactions: TransactionBA[];
  page: number;
  count: number;
  itemsPerPage: number;
  isLoading: boolean;
  isError: boolean;
}

const initialState: TransactionHistoryBAState = {
  transactions: [],
  page: -1,
  count: 0,
  itemsPerPage: -1,
  isLoading: false,
  isError: false,
};

export const transactionHistoryBASlice = createSlice({
  name: 'transactionHistoryBASlice',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchTransactions.pending, state => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(fetchTransactions.fulfilled, (state, action: PayloadAction<TransactionHistoryBAResponse>) => {
      state.isLoading = false;
      state.transactions = action.payload.transactions;
      state.count = action.payload.count;
      state.page = action.payload.page;
      state.itemsPerPage = action.payload.itemsPerPage;
    });
    builder.addCase(fetchTransactions.rejected, state => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export const { reset } = transactionHistoryBASlice.actions;
