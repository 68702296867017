import { useEffect, useState } from 'react';

import { useMsal } from '@azure/msal-react';
import { loginRequest } from '@/auth/msalConfig';
import { useAppDispatch } from '@/lib/redux';
import { setName } from '@/lib/redux/slices/auth/slice';

function useInstanceLoading(): boolean {
  const { instance } = useMsal();
  const [loading, setLoading] = useState(true);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoading(true);
    async function checkAuth(): Promise<void> {
      const account = instance.getActiveAccount();
      let shouldLogout = false;
      if (account === null) {
        shouldLogout = true;
      } else {
        try {
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account,
          });
          dispatch(setName(response.account.name ?? ''));
        } catch (error) {
          shouldLogout = true;
          console.log(error);
        }
      }

      if (shouldLogout) {
        await instance.logoutRedirect({
          onRedirectNavigate: _ => {
            return false;
          },
        });
      }
    }
    void checkAuth().then(() => setLoading(false));
  }, [instance, dispatch]);

  return loading;
}

export { useInstanceLoading };
