import { createAsyncThunk } from '@reduxjs/toolkit';
import { type AxiosResponse } from 'axios';

import authAxios from '@/auth/axios';
import { errorToast, successToast } from '@/utils/toastMessage';
import type { PaymentMethod, CreateResponse, UpdateResponse } from '@/types/PaymentMethod';

interface PaymentMethodResponse {
  data: {
    data: PaymentMethod[];
  };
}

export const getPaymentMethodTopupByGroup = createAsyncThunk(
  'paymentMethodTopup.getData',
  async (data: { countryCode: string; groupId: string | null }, { rejectWithValue }) => {
    try {
      const response = await authAxios.get<PaymentMethodResponse>('/topup/payment-method/paginated', {
        params: {
          country: data.countryCode,
          groupId: data.groupId,
        },
      });

      return response.data.data.data;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);

export const createPaymentMethodTopup = createAsyncThunk(
  'paymentMethodTopup.create',
  async (data: PaymentMethod, { rejectWithValue }) => {
    try {
      const response = await authAxios.post<CreateResponse, AxiosResponse<CreateResponse>, PaymentMethod>(
        '/topup/payment-method',
        data,
      );

      successToast('toast-messages.payment-method-created');

      return response.data;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);

export const updatePaymentMethodTopup = createAsyncThunk(
  'paymentMethodTopup.update',
  async ({ id, ...data }: PaymentMethod, { rejectWithValue }) => {
    try {
      const response = await authAxios.put<UpdateResponse, AxiosResponse<UpdateResponse>, PaymentMethod>(
        `/topup/payment-method/${id}`,
        data,
      );

      successToast('toast-messages.payment-method-updated');

      return response.data;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);
