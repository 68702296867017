import authAxios from '@/auth/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { errorToast, successToast } from '@/utils/toastMessage';

export const getTrustedDevice = createAsyncThunk(
  'trustedDevice.getCustomerTrustedDevice',
  async ({ customerId }: { customerId: string }, { rejectWithValue }) => {
    try {
      const { data } = await authAxios.get('/client-certificates', { headers: { 'x-customer-id': customerId } });
      return data.data;
    } catch (err: any) {
      if (err.response.data.errors[0].errorMessage !== 'err_no_certificates_found') {
        errorToast();
      }
      return rejectWithValue(err);
    }
  },
);

export const revokeTrustedDevice = createAsyncThunk(
  'trustedDevice.patchCustomerTrustedDevice',
  async (
    { revocationReason, certificateSerialNumber }: { revocationReason: string; certificateSerialNumber: string },
    { rejectWithValue },
  ) => {
    try {
      await authAxios.patch(`/client-certificates/${certificateSerialNumber}`, {
        isRevoked: true,
        revocationReason,
      });
      successToast('toast-messages.trusted-device-remove-certificate-success')
      return { certificateSerialNumber, revokationReason: revocationReason };
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);
