import authAxios from '@/auth/axios';
import { type Country } from '@/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { errorToast, successToast } from '@/utils/toastMessage';

type CountryOmit = Omit<Country, 'countryCode' | 'isEnabled' | 'isTesting'> & {
  countryCode?: never;
  isEnabled?: never;
  isTesting?: never;
};

export const addCountry = createAsyncThunk(
  'country.addCountry',
  async ({ body }: { body: Country }, { rejectWithValue }) => {
    try {
      const { data } = await authAxios.post<{ data: Country }>('/countries', body);
      successToast();
      return data.data;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);

export const countryEdit = createAsyncThunk(
  'country.countryEdit',
  async ({ body, countryCode }: { body: CountryOmit; countryCode: string }, { rejectWithValue }) => {
    try {
      const { data } = await authAxios.patch<{ data: Country }>(`/countries/${countryCode}`, body);
      successToast();
      return data.data;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);

export const countryEditIsEnabled = createAsyncThunk(
  'country.countryEditIsEnabled',
  async ({ isEnabled, countryCode }: { isEnabled: boolean; countryCode: string }, { rejectWithValue }) => {
    try {
      const { data } = await authAxios.patch<{ data: Country }>(`/countries/${countryCode}`, { isEnabled });
      successToast();
      return data.data;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);

export const getCountry = createAsyncThunk(
  'country.countryDetails',
  async ({ countryCode }: { countryCode: string }, { rejectWithValue }) => {
    try {
      const { data } = await authAxios.get<{ data: Country }>(`/countries/${countryCode}`);
      return data.data;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);
