import { useMemo } from 'react';
import styled from 'styled-components';
import WidgetBox from '@/components/customer/widget-box/WidgetBox';
import Card from '@/components/customer/overview/payment-cards/card/Card';
import CardHeader from '../payment-cards/card/card-header/CardHeader';
import { toUtcDateString } from '@/utils/dateTimeUtils';
import { usePaymentCards } from '@/utils/hooks/usePaymentCards';
import { useTranslation } from 'react-i18next';
import { Permissions } from '@/types';
import SomethingWentWrong from '@/components/common/something-went-wrong/SomethingWentWrong';

export const CardWrapper = styled.div`
  display: flex;
  gap: 0.875rem;
  @media only screen and (max-width: 1157px) {
    flex-wrap: wrap;
  }
`;

const CardBox = styled.div`
  flex: 1 1;
`;

export interface PaymentCardsProps {
  customerId: string;
}

const PaymentCards: React.FC<PaymentCardsProps> = ({ customerId }: Readonly<PaymentCardsProps>) => {
  const { paymentCards, isError } = usePaymentCards(customerId);
  const { physicalCard, virtualCard } = paymentCards;
  const { t } = useTranslation('customerOverview');

  const memoizeCard = useMemo(() => {
    return [
      {
        key: 1,
        title: t('widgets.cards.virtual-card'),
        subHeader: virtualCard?.activatedAt,
        card: virtualCard,
      },
      {
        key: 2,
        title: t('widgets.cards.physical-card'),
        subHeader: physicalCard?.activatedAt,
        card: physicalCard,
      },
    ];
  }, [virtualCard, physicalCard, t]);

  return (
    <WidgetBox
      title={t('widgets.cards.header-title')}
      linkPath={`/customer/${customerId}/cards`}
      linkLabel={t('widgets.audit-logs.see-all')}
      linkTarget='_self'
      linkPermission={Permissions.cardOverviewRead}
    >
      {isError && <SomethingWentWrong />}
      {!isError && (
        <CardWrapper>
          {memoizeCard.map(card => (
            <CardBox key={card.key}>
              <CardHeader
                title={card.title}
                subHeader={
                  card.subHeader &&
                  t('widgets.cards.activation', {
                    activationDate: toUtcDateString(card.subHeader),
                    interpolation: { escapeValue: false },
                  })
                }
                marginBottom='0.375rem'
              />
              <Card
                card={card.card}
                customerId={customerId}
              />
            </CardBox>
          ))}
        </CardWrapper>
      )}
    </WidgetBox>
  );
};

export default PaymentCards;
