import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionStatus, TransactionType } from '@/types';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import {
  type ActionProps,
  TransactionActionsContext,
  TransactionActionType,
} from '@/components/customer/transaction-history/transaction-actions/TransactionActions';
import ActionButton from '@/components/customer/transaction-history/transaction-actions/ActionButton';
import { useParams } from 'react-router-dom';

import ActionCardChargeback from '@/components/customer/transaction-history/transaction-actions/ActionCardChargeback';
import { isAmountAllowed } from '@/utils/helperFunctions';
import { fetchCardChargebackActionEmptyResult } from '@/lib/redux/slices/transaction-actions/actions';

const CardChargeback: React.FC<ActionProps> = ({ onClose, transaction, setAccessibility }) => {
  const { selectedTransactionAction } = useContext(TransactionActionsContext);
  const { t } = useTranslation(['customerOverview', 'global']);
  const dispatch = useAppDispatch();
  const { customerId } = useParams<{ customerId: string }>();

  const isCardChargebackAllowed =
    transaction?.transactionType === TransactionType.Card && transaction.status === TransactionStatus.Approved;
  const { isCardChargebackEmpty } = useAppSelector((state: RootState) => state.transactionActionsSlice);

  useEffect(() => {
    if (transaction) {
      const { transactionType, status, slipId } = transaction;

      if (
        TransactionType.Card === transactionType &&
        !selectedTransactionAction &&
        slipId &&
        isCardChargebackEmpty === undefined &&
        customerId &&
        status &&
        isCardChargebackAllowed
      ) {
        void dispatch(
          fetchCardChargebackActionEmptyResult({
            customerId,
            slipId,
          }),
        );
      } else if (!isCardChargebackEmpty) {
        setAccessibility(false);
      }
    }
  }, [
    selectedTransactionAction,
    customerId,
    dispatch,
    transaction,
    isCardChargebackEmpty,
    setAccessibility,
    isCardChargebackAllowed,
  ]);

  const requestedAmount = transaction?.requestedAmount ?? 0;

  const amount = `${requestedAmount}`;

  useEffect(() => {
    if (isCardChargebackEmpty !== undefined) {
      setAccessibility(isCardChargebackEmpty);
    }
    return () => {
      setAccessibility(undefined);
    };
  }, [isCardChargebackEmpty, setAccessibility]);

  const handleConfirm = (amount: number, reason: string): void => {
    console.log('Confirm', amount, reason);
  };

  if (selectedTransactionAction === undefined) {
    return (
      <ActionButton
        type={TransactionActionType.CardChargeback}
        hide={!(isCardChargebackEmpty && isCardChargebackAllowed)}
        label='Card Chargeback'
      />
    );
  }

  if (selectedTransactionAction === TransactionActionType.CardChargeback) {
    return (
      <ActionCardChargeback
        disabledButton={false}
        hint={t('widgets.transaction-history.information-text-amount', {
          amount,
        })}
        onConfirm={handleConfirm}
        onClose={onClose}
        isAllowed={values => isAmountAllowed(values, requestedAmount)}
      ></ActionCardChargeback>
    );
  }

  return null;
};

export default CardChargeback;
