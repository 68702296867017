import React, { useEffect, useMemo } from 'react';
import styles from './IdDocsReviewPage.module.scss';
import RemoteImage from '@/components/common/images/RemoteImage';
import { toTitleCase } from '@/utils/typography';
import Typography from '@/components/common/typography/Typography';
import Button from '@/components/common/button/Button';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import { CustomerDocStatus, Permissions } from '@/types';
import { toUtcDateString } from '@/utils/dateTimeUtils';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import ConfirmationModal, { DocType } from '@/components/customer/documents/review/ConfirmationModal';
import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle';
import { useTranslation } from 'react-i18next';
import { reset, setSelectedDocument } from '@/lib/redux/slices/document-review/slice';
import { useKycApplications } from '@/utils/hooks/useKycApplications';
import InputLabel from '@/components/common/labels/InputLabel';
import Input from '@/components/common/inputs/Input';
import SomethingWentWrong from '@/components/common/something-went-wrong/SomethingWentWrong';

export default function IdDocsReviewPage(): React.JSX.Element | null {
  const { confirmedStatus } = useAppSelector(state => state.documentReview);
  const { customer } = useAppSelector((state: RootState) => state.customer);
  const { identityDocs } = useAppSelector((state: RootState) => state.identityDocs);
  const dispatch = useAppDispatch();
  const { t } = useTranslation('documentReviewQueue');

  const { kycInfo, isError } = useKycApplications(customer?.customerId ?? '');

  const documentTitle =
    customer?.customerId && identityDocs
      ? t('document-review-page.page-title-id-docs', {
        customerFullName: `${customer.firstName} ${customer.lastName}`,
        docType: toTitleCase(identityDocs.docType),
      })
      : '';

  useDocumentTitle(documentTitle);

  useEffect(
    () => () => {
      dispatch(reset());
    },
    [dispatch],
  );

  const frontImgProps = useMemo(() => {
    return identityDocs?.frontImageId
      ? {
          url: `/identity-docs/review/${identityDocs.identityDocId}/${identityDocs.frontImageId}`,
          alt: t('document-review-page.front-side'),
          config: {
            headers: {
              'x-customer-id': customer?.customerId,
            },
          },
        }
      : undefined;
  }, [identityDocs?.frontImageId, customer?.customerId, identityDocs?.identityDocId, t]);

  const backImgProps = useMemo(() => {
    return identityDocs?.backImageId
      ? {
          url: `/identity-docs/review/${identityDocs.identityDocId}/${identityDocs.backImageId}`,
          alt: t('document-review-page.back-side'),
          config: {
            headers: {
              'x-customer-id': customer?.customerId,
            },
          },
        }
      : undefined;
  }, [identityDocs?.backImageId, customer?.customerId, identityDocs?.identityDocId, t]);

  const selfieImgProps = useMemo(() => {
    return identityDocs?.selfieImageId
      ? {
          alt: t('document-review-page.selfie-img'),
          url: `/identity-docs/review/${identityDocs.identityDocId}/${identityDocs.selfieImageId}`,
          config: {
            headers: {
              'x-customer-id': customer?.customerId,
            },
          },
        }
      : undefined;
  }, [identityDocs?.selfieImageId, customer?.customerId, identityDocs?.identityDocId, t]);

  const onDeclineButtonClick = (): void => {
    if (identityDocs) {
      dispatch(setSelectedDocument({ status: CustomerDocStatus.REJECTED, documentId: identityDocs.identityDocId }));
    }
  };

  const onApproveButtonClick = (): void => {
    if (identityDocs) {
      dispatch(setSelectedDocument({ status: CustomerDocStatus.APPROVED, documentId: identityDocs.identityDocId }));
    }
  };

  if (confirmedStatus ?? identityDocs?.docStatus !== CustomerDocStatus.REVIEW) {
    const actualStatus = confirmedStatus ?? identityDocs?.docStatus;
    const status = actualStatus === CustomerDocStatus.APPROVED ? 'approved' : 'declined';

    return (
      <div className={styles.main}>
        <div className={styles['white-box']}>
          <Typography className={styles['reviewed-label']}>
            {t('document-review-page.review-status-message', { status: t(status) })}.
          </Typography>
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className={styles.main}>
        <SomethingWentWrong />
      </div>
    );
  }

  if (customer?.customerId && identityDocs) {
    const idvIndicators = kycInfo.idvDocCheckStatuses?.idvIndicators ?? [];
    return (
      <>
        <ConfirmationModal documentType={DocType.idDocs} />
        <div className={styles.main}>
          <div className={styles['white-box']}>
            <div className={styles.documents}>
              <div className={styles['info-wrapper']}>
                <Typography variant='p3Light'>
                  {identityDocs.createdOn ? toUtcDateString(identityDocs.createdOn) : ''}
                </Typography>
                <Typography variant='p3Light'>{toTitleCase(identityDocs.docType)}</Typography>
                <Typography variant='p3Light'>{toTitleCase(identityDocs.docStatus ?? 'Review')}</Typography>
              </div>
              <div className={styles['front-back-wrapper']}>
                {frontImgProps && <RemoteImage {...frontImgProps} />}
                {backImgProps && <RemoteImage {...backImgProps} />}
              </div>
              <div className={styles['selfie-wrapper']}>
                <div className={styles['front-back-wrapper']}>
                  {selfieImgProps && <RemoteImage {...selfieImgProps} />}
                  {kycInfo?.idvDocCheckId && (
                    <div className={styles['kyc-wrapper']}>
                      <InputLabel
                        className={styles['kyc-wrapper-label']}
                        label={'Trulio ID'}
                      >
                        <Input
                          disabled
                          value={kycInfo.idvDocCheckId?.idvSdkFlowId ?? ''}
                        />
                      </InputLabel>
                      {idvIndicators.length > 0 && (
                        <InputLabel label={t('document-review-page.review-reasons')}>
                          {idvIndicators.map((item, index) => (
                            <>
                              <div
                                key={item.result}
                                className={styles['empty-space']}
                              ></div>
                              <Input
                                disabled
                                value={item.result + ' - ' + item.description}
                              />
                            </>
                          ))}
                        </InputLabel>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <UserPermissionsGuard permission={Permissions.docQueueAdmit}>
              <div className={styles.buttons}>
                <Button
                  variant='secondary'
                  onClick={onDeclineButtonClick}
                  textTransform='uppercase'
                >
                  {t('decline')}
                </Button>
                <Button
                  onClick={onApproveButtonClick}
                  textTransform='uppercase'
                >
                  {t('approve')}
                </Button>
              </div>
            </UserPermissionsGuard>
          </div>
        </div>
      </>
    );
  }

  return null;
}
