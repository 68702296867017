import authAxios from '@/auth/axios';
import { CustomerStatusEnum, type Customer } from '@/types';
import { getEnumKeyFromValue } from '@/utils/helperFunctions';
import { createAsyncThunk } from '@reduxjs/toolkit';
import addComment from '../../utils/addComment';
import { successToast, errorToast } from '@/utils/toastMessage';

export const fetchCustomer = createAsyncThunk(
  'customer.fetchCustomer',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const { data } = await authAxios.get<{ data: Customer }>('/customers', { headers: { 'x-customer-id': id } });
      return data.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const updateCustomerStatus = createAsyncThunk(
  'customer.updateCustomerStatus',
  async (
    { customerId, newStatus, comment }: { customerId: string; newStatus: CustomerStatusEnum; comment?: string },
    { rejectWithValue },
  ) => {
    try {
      const statusKey = getEnumKeyFromValue(CustomerStatusEnum, newStatus);
      await authAxios.put(`/customers/${customerId}/statuses/${statusKey}`);
      if (comment) {
        try {
          await addComment(comment, customerId);
        } catch (_: any) {}
      }
      successToast('toast-messages.customer-status-change');
      return statusKey as CustomerStatusEnum;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);

export const updateCustomerDetails = createAsyncThunk(
  'customer.updateCustomerDetails',
  async ({ customerId, body }: { customerId: string; body: object }, { rejectWithValue }) => {
    try {
      await authAxios.patch(
        'customers',
        { ...body },
        {
          headers: { 'x-customer-id': customerId },
        },
      );
      successToast('toast-messages.customer-information-edit');
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);
