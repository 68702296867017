import { type CurrencyEnum } from './Currencies';

export enum TransactionStatus {
  Pending = 'Pending',
  Failed = 'Failed',
  Declined = 'Declined',
  Expired = 'Expired',
  Approved = 'Approved',
  Initiated = 'Initiated',
  InProgress = 'InProgress',
  Reversed = 'Reversed',
}

export enum TransactionType {
  Topup = 'Topup',
  Withdrawal = 'Withdrawal',
  Transfer = 'Transfer',
  Card = 'Card',
  PaymentFromCustomer = 'PaymentFromCustomer',
  PaymentToCustomer = 'PaymentToCustomer',
  MerchantTopUp = 'MerchantTopUp',
  MerchantWithdrawal = 'MerchantWithdrawal',
  Exchange = 'Exchange',
  AdjustmentCredit = 'AdjustmentCredit',
  AdjustmentDebit = 'AdjustmentDebit',
  Chargeback = 'Chargeback',
  WalletRefundDebit = 'WalletRefundDebit',
  WalletRefundCredit = 'WalletRefundCredit',
  ChargebackReversal = 'ChargebackReversal',
  Reversal = 'Reversal',
  MerchantPaymentReversal = 'MerchantPaymentReversal',
  CardFee = 'CardFee',
  CardFeeReversal = 'CardFeeReversal',
  CardFraudRefund = 'CardFraudRefund',
  CardChargeback = 'CardChargeback',
}

export interface Fee {
  amount: number;
}

interface Source {
  balanceAmountCurrency?: string;
  customerId?: string;
  balanceAmount?: number;
  accountId?: string;
  allowedToShowTrx?: boolean;
  percentFee?: Fee;
  percentFxFee?: Fee;
  fixFee?: Fee;
  fixFxFee?: Fee;
}

interface Destination {
  customerId: string;
  balanceAmount?: number;
  balanceAmountCurrency?: string;
  percentFee?: Fee;
  percentFxFee?: Fee;
  fixFee?: Fee;
  fixFxFee?: Fee;
}

interface PaymentDetail {
  paymentMethodName: string;
  providerTransactionId: string;
}

export interface Transaction {
  transactionId?: string;
  slipId?: string;
  paymentDetail?: PaymentDetail;
  requestedAmount?: number;
  metadata?: string;
  destination?: Destination;
  providerTransactionId?: string;
  source?: Source;
  expireDate?: Date | string;
  requestedAmountCurrency?: CurrencyEnum;
  status?: TransactionStatus;
  transactionType?: TransactionType;
  statusReason?: string;
  createdDate?: Date | string;
  updatedDate?: Date | string;
}
