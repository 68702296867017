import Spinner from '@/components/common/spinners/Spinner';
import CustomerCardsTable from '@/components/customer/cards/CustomerCardsTable';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import { useCallback, useEffect } from 'react';
import { fetchPaymentCards } from '@/lib/redux/slices/customer-cards/actions';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle';
import { type ClickOptions } from '@/utils/hooks/useMouseClicks';
import { useLocation, useNavigate } from 'react-router-dom';
import SomethingWentWrong from '@/components/common/something-went-wrong/SomethingWentWrong';

export default function CardsWidget(): React.JSX.Element {
  const { isLoading, isError, cards } = useAppSelector((state: RootState) => state.customerCards);
  const customer = useAppSelector((state: RootState) => state.customer.customer);
  const dispatch = useAppDispatch();
  const loc = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation('customerOverview');
  useDocumentTitle(
    customer
      ? t('navigation-tabs.cards-page-title', { customerFullName: `${customer.firstName} ${customer.lastName}` })
      : '',
  );

  useEffect(() => {
    if (customer?.customerId !== undefined) {
      void dispatch(fetchPaymentCards({ customerId: customer?.customerId }));
    }
  }, [customer?.customerId, dispatch]);

  const onClick = useCallback(
    (id: string, options?: ClickOptions) => {
      const url = `${loc.pathname}${loc.pathname.endsWith('/') ? '' : '/'}${id}`;
      if (options?.isCtrlDown || options?.isMiddleMouseKey) {
        window.open(url, '_blank');
      } else {
        navigate(url);
      }
    },
    [loc, navigate],
  );

  if (isError) {
    return <SomethingWentWrong />;
  }

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && (
        <CustomerCardsTable
          cards={cards}
          onClick={onClick}
        />
      )}
    </>
  );
}
