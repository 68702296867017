import { useTranslation } from 'react-i18next';
import Table from '@/components/common/tables/Table';
import WidgetBox from '../../widget-box/WidgetBox';
import { type AssociatedAffiliate } from '@/types';

const Affiliates = ({ affiliate }: { affiliate?: AssociatedAffiliate }): JSX.Element => {
  const { t } = useTranslation('customerOverview');
  return (
    <WidgetBox title={t('widgets.affiliates.title')}>
      <Table cols={2}>
        <Table.Row>
          <Table.Cell>{t('widgets.affiliates.affiliate')}</Table.Cell>
          <Table.Cell>{affiliate?.affiliateId}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>{t('widgets.affiliates.site')}</Table.Cell>
          <Table.Cell>{affiliate?.siteId}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>{t('widgets.affiliates.banner')}</Table.Cell>
          <Table.Cell>{affiliate?.bannerId}</Table.Cell>
        </Table.Row>
      </Table>
    </WidgetBox>
  );
};

export default Affiliates;
