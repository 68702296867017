import { useMemo, useCallback } from 'react';
import { type Countries } from '@/types';
import ClickableRow from '@/components/common/tables/ClickableRow';
import { useNavigate } from 'react-router-dom';
import { type ClickOptions } from '@/utils/hooks/useMouseClicks';
import { useTranslation } from 'react-i18next';

export default function TableRow({ country }: Readonly<{ country: Countries }>): React.JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation('configurations');

  const onClick = useCallback(
    (id: string, options?: ClickOptions) => {
      const url = `/configurations/countries/${country.countryCode}/country-information`;
      if (options?.isCtrlDown || options?.isMiddleMouseKey) {
        window.open(url, '_blank');
      } else {
        navigate(url);
      }
    },
    [navigate, country],
  );

  const cells = useMemo(() => {
    return [
      { content: country.countryCode, key: 'countryCode' },
      { content: country.countryName, key: 'countryName' },
      { content: country.isEnabled ? t('countries-table.yes') : t('countries-table.no'), key: 'isEnabled' },
      { content: country.internationalCallingCode, key: 'internationalCallingCode' },
    ];
  }, [country, t]);

  return (
    <ClickableRow
      onClick={opt => {
        onClick(country?.countryCode ?? '', opt);
      }}
      cells={cells}
    />
  );
}
