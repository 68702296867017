import { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '@/lib/redux';

import Table from '@/components/common/tables/Table';
import WidgetBox from '../../widget-box/WidgetBox';
import { getTrustedDevice, revokeTrustedDevice } from '@/lib/redux/slices/trusted-device/actions';
import { toUtcDateString, getValidDate } from '@/utils/dateTimeUtils';
import Spinner from '@/components/common/spinners/Spinner';
import { type TrustedDevice } from '@/types/TrustedDevice';
import StatusStamp from '@/components/common/status-stamp/StatusStamp';
import LinkButton from '@/components/common/button/LinkButton';
import styles from './TrustedDeviceWidget.module.scss';
import ConfirmationModal from '@/components/common/modals/confirmation/ConfirmModal';
import SomethingWentWrong from '@/components/common/something-went-wrong/SomethingWentWrong';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import { mediumPurple } from '@/utils/colors';
import { Permissions } from '@/types';

const TrustedDeviceWidget = (): JSX.Element => {
  const { t } = useTranslation('customerOverview');
  const dispatch = useAppDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { customer } = useAppSelector(state => state.customer);
  const { loading, isRevokeSuccessful, isError, isPending, trustedDevice } = useAppSelector(
    state => state.trustedDevice,
  );
  const latestDevice: TrustedDevice | undefined =
    trustedDevice && trustedDevice.length > 0 ? trustedDevice[0] : undefined;
  const customerId = customer?.customerId;
  const defaultStringValue = 'N/A';

  useEffect(() => {
    if (customerId) {
      void dispatch(getTrustedDevice({ customerId }));
    }
  }, [customerId, dispatch]);

  useEffect(() => {
    if (customerId && isRevokeSuccessful) {
      void dispatch(getTrustedDevice({ customerId }));
      setIsModalOpen(false);
    }
  }, [customerId, dispatch, isRevokeSuccessful]);

  const renderDateField = useCallback((validTrustedDeviceDate: string | undefined): string | undefined => {
    if (validTrustedDeviceDate) {
      return toUtcDateString(getValidDate(validTrustedDeviceDate));
    }

    return defaultStringValue;
  }, []);

  const handleDelete = (): void => {
    setIsModalOpen(true);
  };

  const onConfirm = useCallback(
    (comment?: string) => {
      if (latestDevice?.certificateSerialNumber && comment) {
        void dispatch(
          revokeTrustedDevice({
            revocationReason: comment,
            certificateSerialNumber: latestDevice?.certificateSerialNumber,
          }),
        );
      }
    },
    [dispatch, latestDevice],
  );

  const onDeny = (): void => {
    setIsModalOpen(false);
  };

  return (
    <WidgetBox
      title={t('widgets.trusted-device.title')}
      label={latestDevice || isError ? undefined : t('widgets.trusted-device.no-certificates')}
      labelVariant='p2Semibold'
      labelColor={mediumPurple.cssColor}
    >
      <div className={styles['trusted-device-widget__content-container']}>
        {isError && (
          <div className={styles['trusted-device-widget__error-overlay']}>
            <SomethingWentWrong />
          </div>
        )}
        {loading && <Spinner />}
        {!loading && (
          <Table cols={1}>
            <Table.Row>
              <Table.Cell>
                {t('widgets.trusted-device.device-id')}
                {latestDevice?.deviceId ?? defaultStringValue}
                {latestDevice && (
                  <>
                    <div>
                      <StatusStamp
                        status={
                          latestDevice?.isRevoked
                            ? t('widgets.trusted-device.deleted')
                            : t('widgets.trusted-device.trusted')
                        }
                        variant={latestDevice?.isRevoked ? 'errorDark' : 'successDark'}
                      />
                    </div>
                    {!latestDevice?.isRevoked && (
                      <UserPermissionsGuard permission={Permissions.customerManage}>
                        <div className={styles['trusted-device-widget__delete-button']}>
                          <LinkButton onClick={handleDelete}>{t('widgets.trusted-device.delete')}</LinkButton>
                        </div>
                      </UserPermissionsGuard>
                    )}
                  </>
                )}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {t('widgets.trusted-device.expire-on')}
                {renderDateField(latestDevice?.expirationTimeStamp)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {t('widgets.trusted-device.deleted-on')}
                {renderDateField(latestDevice?.revocationTimeStamp)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {t('widgets.trusted-device.reason')}
                {latestDevice?.revocationReason || defaultStringValue}
              </Table.Cell>
            </Table.Row>
          </Table>
        )}
      </div>
      {isModalOpen && (
        <ConfirmationModal
          title={t('widgets.trusted-device.confirm-modal-title')}
          onDeny={onDeny}
          onConfirm={onConfirm}
          hasComment
          requiredComment
          disabled={isPending}
        />
      )}
    </WidgetBox>
  );
};

export default TrustedDeviceWidget;
