import { useEffect, useState } from 'react';
import Input, { type InputProps } from './Input';
import { useDebounce } from 'use-debounce';

export interface Props extends InputProps {
  delay?: number;
  valueMaxLength?: number;
}

export default function DebounceInput({ value, onChange, delay = 500, valueMaxLength, ...rest }: Readonly<Props>): React.JSX.Element {
  const [inputValue, setInputValue] = useState(value);
  const [debounceValue] = useDebounce(inputValue, delay);

  useEffect(() => {
    onChange?.(debounceValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue]);

  useEffect(() => {
    setInputValue(() => value);
  }, [setInputValue, value]);

  const handleInputValue = (val: string): void => {
    if (valueMaxLength !== undefined && val.length > valueMaxLength) {
      return;
    }
    setInputValue(() => val);
  };

  return (
    <Input
      value={inputValue}
      onChange={handleInputValue}
      {...rest}
    />
  );
}
