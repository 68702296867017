import { createAsyncThunk } from '@reduxjs/toolkit';
import { type AxiosResponse } from 'axios';

import authAxios from '@/auth/axios';
import { errorToast, successToast } from '@/utils/toastMessage';
import type PaymentMethodWithdrawals from '@/types/PaymentMethodWithdrawal';
import type { CreateResponse, UpdateResponse } from '@/types/PaymentMethodWithdrawal';

interface PaymentMethodWithdrawalsResponse {
  data: PaymentMethodWithdrawals;
}

export const getPaymentMethodsWithdrawals = createAsyncThunk(
  'paymentMethodsWithdrawals.getPaymentMethodsWithdrawals',
  async (countryCode: string, { rejectWithValue }) => {
    try {
      const response = await authAxios.get<PaymentMethodWithdrawalsResponse>(`/payment-methods/${countryCode}`);

      return response.data.data;
    } catch (err: any) {
      if (err.response.data?.errors[0]?.errorMessage !== 'err_resource_not_found') {
        errorToast();
      }
      return rejectWithValue(err);
    }
  },
);

export const savePaymentMethodsWithdrawals = createAsyncThunk(
  'paymentMethodsWithdrawals.savePaymentMethodsWithdrawals',
  async (data: PaymentMethodWithdrawals, { rejectWithValue }) => {
    try {
      const response = await authAxios.post<CreateResponse, AxiosResponse<CreateResponse>, PaymentMethodWithdrawals>(
        '/payment-methods',
        data,
      );

      successToast('toast-messages.payment-method-created');

      return response.data;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);

export const updatePaymentMethodsWithdrawals = createAsyncThunk(
  'paymentMethodsWithdrawals.updatePaymentMethodsWithdrawals',
  async ({ data, methodsId }: { data: PaymentMethodWithdrawals; methodsId: string }, { rejectWithValue }) => {
    try {
      const response = await authAxios.patch<UpdateResponse, AxiosResponse<CreateResponse>, PaymentMethodWithdrawals>(
        `/payment-methods/${methodsId}`,
        data,
      );
      successToast('toast-messages.payment-method-updated');

      return response.data;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);
