import React from 'react';
import styled from 'styled-components';

import { lightGrey } from '@/utils/colors';

const StyledButton = styled.button<{ $height?: string }>`
  cursor: pointer;
  display: flex;
  padding: 0rem 0.781rem;
  border-radius: 1.25rem;
  height: ${({ $height }) => $height ?? '1.875rem'};
  align-items: center;

  &:disabled {
    color: ${lightGrey.cssColor};
    cursor: not-allowed;
  }
`;

interface StyledIconButtonProps {
  onClick: () => void;
  icon: React.ReactNode;
  disabled?: boolean;
  height?: string;
  'data-testid'?: string;
}

const StyledIconButton = ({ onClick, icon, height, ...rest }: Readonly<StyledIconButtonProps>): JSX.Element => {
  return (
    <StyledButton
      onClick={onClick}
      $height={height}
      {...rest}
    >
      {icon}
    </StyledButton>
  );
};

export default StyledIconButton;
