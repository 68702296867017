import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getTrustedDevice, revokeTrustedDevice } from './actions';
import { type TrustedDevice } from '@/types/TrustedDevice';

export interface TrustedDeviceSliceState {
  trustedDevice?: TrustedDevice[];
  loading: boolean;
  isRevokeSuccessful: boolean;
  isError: boolean;
  isPending: boolean;
}

const initialState: TrustedDeviceSliceState = {
  trustedDevice: [],
  loading: true,
  isRevokeSuccessful: false,
  isError: false,
  isPending: false,
};

export const trustedDeviceSlice = createSlice({
  name: 'trustedDevice',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(getTrustedDevice.fulfilled, (state, action) => {
      state.trustedDevice = [...action.payload];
      state.loading = false;
      state.isRevokeSuccessful = false;
      state.isError = false;
    });
    builder.addCase(getTrustedDevice.rejected, (state, action: PayloadAction<any>) => {
      if (action.payload?.response?.data?.errors[0]?.errorMessage === 'err_no_certificates_found') {
        state.trustedDevice = [];
        state.loading = false;
        state.isRevokeSuccessful = false;
      } else {
        state.isError = true;
      }
      state.loading = false;
    });
    builder.addCase(revokeTrustedDevice.pending, state => {
      state.isPending = true;
    });
    builder.addCase(revokeTrustedDevice.fulfilled, (state, action) => {
      state.trustedDevice = state.trustedDevice?.map(device => {
        if (device.certificateSerialNumber === action.payload.certificateSerialNumber) {
          return {
            ...device,
            isRevoked: true,
            revocationReason: action.payload.revokationReason,
          };
        }

        return device;
      });
      state.loading = false;
      state.isPending = false;
      state.isRevokeSuccessful = true;
    });
    builder.addCase(revokeTrustedDevice.rejected, state => {
      state.loading = false;
      state.isRevokeSuccessful = false;
      state.isPending = false;
    });
  },
});

export const { reset } = trustedDeviceSlice.actions;
