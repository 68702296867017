import { useCallback, type FC } from 'react';

import { useTranslation } from 'react-i18next';

import StyledIconButton from '@/components/common/button/StyledIconButton';
import Table from '@/components/common/tables/Table';
import EditIcon from '@/components/icons/EditIcon';
// import RelateIcon from '@/components/icons/RelateIcon';
import { useAppDispatch } from '@/lib/redux';
import { getChannelById } from '@/lib/redux/slices/channels-search/actions';
import { openModal } from '@/lib/redux/slices/payment-methods-topup/slice';
import styles from '@/pages/configurations/countries-configurations/CountriesConfigurations.module.scss';
import { type PaymentMethod } from '@/types/PaymentMethod';

type Props = Readonly<{ paymentMethod: PaymentMethod; isReadOnly: boolean }>;

const PaymentMethodsTableRow: FC<Props> = ({ paymentMethod, isReadOnly }) => {
  const { t } = useTranslation('configurations');
  const dispatch = useAppDispatch();

  const handleOpenModal = useCallback(() => {
    void dispatch(getChannelById(paymentMethod.channelId));
    dispatch(openModal({ mode: 'opened-edit', data: paymentMethod }));
  }, [dispatch, paymentMethod]);

  return (
    <Table.Row>
      <Table.Cell>{paymentMethod.name}</Table.Cell>
      <Table.Cell>{paymentMethod.id}</Table.Cell>
      <Table.Cell>{paymentMethod.order}</Table.Cell>
      <Table.Cell>{paymentMethod.reason}</Table.Cell>
      <Table.Cell>{paymentMethod.channelId}</Table.Cell>
      <Table.Cell>{paymentMethod.isEnabled ? t('payment-method-topup.yes') : t('payment-method-topup.no')}</Table.Cell>
      {!isReadOnly && (
        <Table.Cell>
          <div className={styles.actions}>
            <StyledIconButton
              onClick={handleOpenModal}
              icon={<EditIcon />}
              data-testid='edit-action'
            />
            {/*
          Add delete action once the ticket is added to a Sprint
          <RelateIcon color='lightgrey' />
          */}
          </div>
        </Table.Cell>
      )}
    </Table.Row>
  );
};

export default PaymentMethodsTableRow;
