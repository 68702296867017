import { useState, useEffect } from 'react';
import ContentBox from '@/components/common/content-box/ContentBox';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import { fetchFeatureFlags } from '@/lib/redux/slices/feature-flags/actions';
import styles from '@/pages/configurations/countries-configurations/CountriesConfigurations.module.scss';
import Typography from '@/components/common/typography/Typography';
import { useTranslation } from 'react-i18next';
import { FeatureFlagDetails } from '@/components/feature-flag/FeatureFlagDetails';
import Spinner from '@/components/common/spinners/Spinner';
import Button from '@/components/common/button/Button';
import { FeatureModal } from '@/components/configurations';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import { Permissions } from '@/types';

export default function FeatureFlags(): React.JSX.Element {
  const { featureFlags, loading } = useAppSelector((state: RootState) => state.featureFlags);
  const [openAddFeatureFlag, setOpenAddFeatureFlag] = useState(false);
  const { t } = useTranslation('configurations');
  const dispatch = useAppDispatch();
  useEffect(() => {
    void dispatch(fetchFeatureFlags());
  }, [dispatch]);

  const handleOpenAddFeatureFlag = (): void => setOpenAddFeatureFlag(true);
  const handleCloseAddFeatureFlag = (): void => setOpenAddFeatureFlag(false);

  return (
    <ContentBox>
      <div className={styles.title}>
        <Typography variant='p1SemiBold'>{t('feature-flags.title')}</Typography>
        <UserPermissionsGuard permission={Permissions.countryConfigEdit}>
          <Button onClick={handleOpenAddFeatureFlag}>+ {t('feature-flags.new-feature')}</Button>
        </UserPermissionsGuard>
      </div>
      {loading && <Spinner />}
      {!loading &&
        featureFlags.map(flag => (
          <FeatureFlagDetails
            key={flag.id}
            flag={flag}
          />
        ))}
      <div className={styles.emptyCard}></div>
      <FeatureModal
        open={openAddFeatureFlag}
        onClose={handleCloseAddFeatureFlag}
      />
    </ContentBox>
  );
}
