import { useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { type Country, type Currency, type ViewMode } from '@/types';
import Typography from '@/components/common/typography/Typography';
import Input from '@/components/common/inputs/Input';
import InputLabel from '@/components/common/labels/InputLabel';
import { mediumPurple } from '@/utils/colors';
import CountryParametersCurrency from '@/components/configurations/country-parameters/CountryParametersCurrency';
import Button from '@/components/common/button/Button';
import { useAppDispatch, useAppSelector } from '@/lib/redux';
import { objectDeepEquality, parseNumberOrDefault } from '@/utils/helperFunctions';
import { addCountry, countryEdit } from '@/lib/redux/slices/country/actions';
import { AccordionTitle } from '@/components/configurations';
import Accordion from '@/components/common/accordion/Accordion';

import styles from './CountryParameters.module.scss';

export const GridItem = styled.div<{ $cell?: [number?, number?] }>`
  grid-column: ${({ $cell = [1, 1] }) => {
    const start = $cell[0];
    const span = $cell[1] ?? 1;
    return `${start} / span ${span}`;
  }};
  @media (max-width: 1090px) {
    grid-column: 1 / span 5;
  }
`;

interface CountryParametersProps {
  mode: ViewMode;
}

const CountryParameters = ({ mode }: CountryParametersProps): JSX.Element => {
  const { t } = useTranslation('configurations');

  const dispatch = useAppDispatch();
  const { country, isLoading } = useAppSelector(state => state.country);

  const [active, setActive] = useState<boolean>(false);
  const [countryParameters, setCountryParameters] = useState<Country>({
    ...country,
    currencies:
      country.currencies.length > 0
        ? country.currencies
        : [{ currencyName: '', currencyCode: '', position: '', precision: 0, symbol: '', isLocal: true }],
  });

  const isNew = mode === 'add';
  const isReadOnly = mode === 'read';
  const isChanged = useMemo(() => !objectDeepEquality(countryParameters, country), [countryParameters, country]);

  const setCurrency = (newCurrency: Currency, isLocal: boolean): void => {
    const previousCountry = countryParameters;
    const updatedCurrencies = previousCountry.currencies.map(item => (item.isLocal === isLocal ? newCurrency : item));
    if (!updatedCurrencies.some(item => item.isLocal === isLocal)) {
      updatedCurrencies.push(newCurrency);
    }
    setCountryParameters({ ...previousCountry, currencies: updatedCurrencies });
  };

  const areCurrenciesValid = useCallback((): boolean => {
    // Filter the currencies based on the presence of required fields
    const currenciesValid = countryParameters.currencies.filter(currency => {
      return (
        currency.currencyCode.length > 0 ||
        currency.currencyName.length > 0 ||
        currency.symbol.length > 0 ||
        currency.position.length > 0
      );
    });
    // If none of the currencies have valid fields, return false
    if (currenciesValid.length === 0) {
      return false;
    }
    // Ensure that every filtered currency has all required fields with valid values
    return currenciesValid.every(
      currency =>
        currency &&
        currency.currencyCode?.length > 0 &&
        currency.currencyName?.length > 0 &&
        currency.symbol?.length > 0 &&
        currency.position?.length > 0 &&
        typeof currency.precision === 'number',
    );
  }, [countryParameters]);

  const hasValidCountryParametersInputs = useCallback((): boolean => {
    let minAppVersionValid = true;
    if (isNew) {
      minAppVersionValid = countryParameters.minAppVersion.length > 0;
    }
    const countryCodeValid = countryParameters.countryCode.length > 0;
    const countryNameValid = countryParameters.countryName.length > 0;
    const internationalCallingCodeValid = countryParameters.internationalCallingCode > 0;
    const minPhoneLengthValid = countryParameters.minPhoneLength > 0;
    const maxPhoneLengthValid = countryParameters.maxPhoneLength > 0;
    const currenciesValid = areCurrenciesValid();
    return (
      countryCodeValid &&
      countryNameValid &&
      currenciesValid &&
      internationalCallingCodeValid &&
      minPhoneLengthValid &&
      maxPhoneLengthValid &&
      minAppVersionValid
    );
  }, [areCurrenciesValid, countryParameters, isNew]);

  const handleSubmit = (): void => {
    const currenciesValid = countryParameters.currencies.filter(currency => {
      return (
        currency.currencyCode.length > 0 ||
        currency.currencyName.length > 0 ||
        currency.symbol.length > 0 ||
        currency.position.length > 0
      );
    });

    countryParameters.currencies = currenciesValid;

    if (isNew) {
      void dispatch(addCountry({ body: countryParameters }));
    } else if (isChanged) {
      const { countryCode, isEnabled, isTesting, ...filtered } = countryParameters;
      void dispatch(countryEdit({ body: filtered, countryCode: countryCode.toUpperCase() }));
    }
  };

  const renderContent = (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <div className={styles.label}>
          <Typography
            variant='p3'
            color={mediumPurple.cssColor}
          >
            {t('new-country.country-parameters-description')}
          </Typography>
        </div>
        <div className={styles.inputCard}>
          <div className={styles.gridCard}>
            <GridItem $cell={[1, 2]}>
              <InputLabel label={t('new-country.input-label.countryCode', { defaultValue: 'New type' })}>
                <Input
                  onChange={(value: string) => {
                    setCountryParameters({ ...countryParameters, countryCode: value.toUpperCase() });
                  }}
                  value={countryParameters.countryCode}
                  disabled={!isNew || isReadOnly}
                />
              </InputLabel>
            </GridItem>

            <GridItem $cell={[3, 2]}>
              <InputLabel label={t('new-country.input-label.countryName', { defaultValue: 'New type' })}>
                <Input
                  onChange={(value: string) => setCountryParameters({ ...countryParameters, countryName: value })}
                  value={countryParameters.countryName}
                  disabled={isReadOnly}
                />
              </InputLabel>
            </GridItem>

            <GridItem $cell={[5, 2]}>
              <InputLabel label={t('new-country.input-label.internationalCallingCode', { defaultValue: 'New type' })}>
                <Input
                  onChange={(value: string) =>
                    setCountryParameters({
                      ...countryParameters,
                      internationalCallingCode: parseNumberOrDefault(value, country.internationalCallingCode),
                    })
                  }
                  value={String(countryParameters.internationalCallingCode)}
                  disabled={isReadOnly}
                />
              </InputLabel>
            </GridItem>

            <GridItem $cell={[1, 2]}>
              <InputLabel label={t('new-country.input-label.minPhoneLength', { defaultValue: 'New type' })}>
                <Input
                  onChange={(value: string) =>
                    setCountryParameters({
                      ...countryParameters,
                      minPhoneLength: parseNumberOrDefault(value, countryParameters.minPhoneLength),
                    })
                  }
                  value={String(countryParameters.minPhoneLength)}
                  disabled={isReadOnly}
                />
              </InputLabel>
            </GridItem>

            <GridItem $cell={[3, 2]}>
              <InputLabel label={t('new-country.input-label.maxPhoneLength', { defaultValue: 'New type' })}>
                <Input
                  onChange={(value: string) =>
                    setCountryParameters({
                      ...countryParameters,
                      maxPhoneLength: parseNumberOrDefault(value, countryParameters.maxPhoneLength),
                    })
                  }
                  value={String(countryParameters.maxPhoneLength)}
                  disabled={isReadOnly}
                />
              </InputLabel>
            </GridItem>

            <GridItem $cell={[5, 2]}>
              <InputLabel label={t('new-country.input-label.minAppVersion', { defaultValue: 'New type' })}>
                <Input
                  onChange={(value: string) => setCountryParameters({ ...countryParameters, minAppVersion: value })}
                  value={countryParameters.minAppVersion ?? ''}
                  disabled={isReadOnly}
                />
              </InputLabel>
            </GridItem>

            <GridItem $cell={[1, 2]}>
              <InputLabel label={t('new-country.input-label.zipCodeRegex', { defaultValue: 'New type' })}>
                <Input
                  onChange={(value: string) => {
                    setCountryParameters({ ...countryParameters, zipCodeRegex: value });
                  }}
                  value={countryParameters.zipCodeRegex ?? ''}
                  disabled={isReadOnly}
                />
              </InputLabel>
            </GridItem>

            <GridItem $cell={[1, 6]}>
              <Typography variant='p3Bold'>{t('new-country.local-currency')}</Typography>
            </GridItem>
            <CountryParametersCurrency
              currency={countryParameters.currencies.find(item => item.isLocal)}
              isLocal={true}
              setCurrency={(currency: Currency) => setCurrency(currency, true)}
              isReadOnly={isReadOnly}
            />
            <GridItem $cell={[1, 6]}>
              <Typography variant='p3Bold'>{t('new-country.second-currency')}</Typography>
            </GridItem>

            <CountryParametersCurrency
              currency={countryParameters.currencies.find(item => !item.isLocal)}
              isLocal={false}
              setCurrency={(currency: Currency) => setCurrency(currency, false)}
              isReadOnly={isReadOnly}
            />
          </div>
        </div>
      </div>
      {!isReadOnly && (
        <div className={styles.buttons}>
          <Button
            textTransform='uppercase'
            disabled={isLoading || !isChanged || !hasValidCountryParametersInputs()}
            onClick={handleSubmit}
          >
            {t('new-country.button-submit-configuration')}
          </Button>
        </div>
      )}
    </div>
  );

  return (
    <Accordion
      title={
        <AccordionTitle
          isValid={hasValidCountryParametersInputs() && !isChanged}
          title='Country parameters'
        />
      }
      content={renderContent}
      isActive={active}
      onClick={() => setActive(!active)}
    />
  );
};

export default CountryParameters;
