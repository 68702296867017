import styled from 'styled-components';
import { toRem } from 'k8-web-lib-tmp';
import { mediumPurple } from '@/utils/colors';

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: ${toRem(20)};
  margin-bottom: ${toRem(24)};
`;

export const GridItem = styled.div<{ $cell?: [number?, number?]; $align?: string }>`
  grid-column: ${({ $cell = [1, 1] }) => {
    const start = $cell[0];
    const span = $cell[1] ?? 1;
    return `${start} / span ${span}`;
  }};
  display: flex;
  flex-direction: row;
  justify-content: ${({ $align }) => $align ?? 'center'};
  align-content: center;

  &:last-of-type {
    padding-top: ${toRem(22)};
  }

  @media (max-width: 1090px) {
    grid-column: 1 / span 8;

    &:last-of-type {
      padding-top: 0;
    }
  }
`;

export const CheckboxLabel = styled.span`
  display: inline-block;
  margin-top: ${toRem(2)};
  padding-left: ${toRem(8)};
  color : ${mediumPurple.cssColor};
  font-weight: bold;
  font-size: ${toRem(14)};
`;

export const CheckboxWrapper = styled.span`
  padding-top: ${toRem(2)};
  
  label {
    gap: 0;
  }

  input + span + span {
    padding-right: 0.49rem;
  }

  input:disabled + span, input:disabled + span + span {
    cursor: not-allowed;
  }
`;

export const TitleContainer = styled.div`
  margin-bottom: ${toRem(24)}
`
