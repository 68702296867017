import { createSlice } from '@reduxjs/toolkit';
import { addTermsAndConditions, addPrivacyPolicy, getComplianceDocs, type ComplianceDocs } from './actions';

export enum ComplianceDocType {
  PrivacyPolicy = 'privacy-policy',
  TermsAndConditions = 'terms-and-conditions',
}
export interface ComplianceDocsState {
  isLoadingComplianceDocs: boolean;
  successfulTermsAndCondition: boolean;
  successfulPolicy: boolean;
  policy: ComplianceDocs;
  termsAndCondition: ComplianceDocs;
  hasExistingPolicyVersion: boolean;
  hasExistingTermsAndConditionsVersion: boolean;
}

const initialState: ComplianceDocsState = {
  isLoadingComplianceDocs: true,
  successfulTermsAndCondition: false,
  successfulPolicy: false,
  hasExistingPolicyVersion: false,
  hasExistingTermsAndConditionsVersion: false,
  policy: {
    countryCode: '',
    complianceDocVersion: '',
    complianceDocType: ComplianceDocType.PrivacyPolicy,
    complianceDocUrl: '',
  },
  termsAndCondition: {
    countryCode: '',
    complianceDocVersion: '',
    complianceDocType: ComplianceDocType.TermsAndConditions,
    complianceDocUrl: '',
  },
};

export const complianceDocsSlice = createSlice({
  name: 'complianceDocs',
  initialState,
  reducers: {
    resetComplianceDocsTermsAndCondition(state) {
      state.successfulTermsAndCondition = false;
    },
    resetComplianceDocsPolicy(state) {
      state.successfulPolicy = false;
    },
    resetComplianceDocsLoading(state: ComplianceDocsState) {
      state.isLoadingComplianceDocs = false;
    },
    resetComplianceDocs() {
      return { ...initialState };
    },
    resetHasExistingPolicyVersion(state) {
      state.hasExistingPolicyVersion = false;
    },
    resetHasExistingTermsAndConditionsVersion(state) {
      state.hasExistingTermsAndConditionsVersion = false;
    },
  },
  extraReducers: builder => {
    // Compliance docs
    builder.addCase(getComplianceDocs.pending, state => {
      state.isLoadingComplianceDocs = true;
    });
    builder.addCase(getComplianceDocs.fulfilled, (state, action) => {
      state.isLoadingComplianceDocs = false;
      state.termsAndCondition = action.payload.termsAndCondition ?? initialState.termsAndCondition;
      state.policy = action.payload.policy ?? initialState.policy;
    });
    builder.addCase(getComplianceDocs.rejected, state => {
      state.isLoadingComplianceDocs = false;
    });
    // Terms and conditions
    builder.addCase(addTermsAndConditions.rejected, state => {
      state.hasExistingTermsAndConditionsVersion = true
    });
    builder.addCase(addTermsAndConditions.fulfilled, (state, action) => {
      state.successfulTermsAndCondition = true;
      state.hasExistingTermsAndConditionsVersion = false;
      state.termsAndCondition = action.payload;
    });
    // Privacy policy
    builder.addCase(addPrivacyPolicy.rejected, state => {
      state.hasExistingPolicyVersion = true;
    });
    builder.addCase(addPrivacyPolicy.fulfilled, (state, action) => {
      state.successfulPolicy = true;
      state.hasExistingPolicyVersion = false;
      state.policy = action.payload;
    });
  },
});

export const {
  resetComplianceDocsTermsAndCondition,
  resetComplianceDocsPolicy,
  resetComplianceDocsLoading,
  resetComplianceDocs,
  resetHasExistingPolicyVersion,
  resetHasExistingTermsAndConditionsVersion,
} = complianceDocsSlice.actions;
