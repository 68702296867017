import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchPaymentCards, updatePaymentCard } from './actions';
import { type PaymentCardStatus, type PaymentCardsInfo } from '@/types';

export interface FetchPaymentCardsState {
  key: string;
  paymentCards: PaymentCardsInfo;
  updating: boolean;
  updateSuccess: boolean;
  isError: boolean;
}

const initialState: FetchPaymentCardsState = {
  key: '',
  paymentCards: {
    customerId: '',
    physicalCard: undefined,
    virtualCard: undefined,
  },
  updating: false,
  updateSuccess: false,
  isError: false,
};

export const paymentCardsSlice = createSlice({
  name: 'paymentCards',
  initialState,
  reducers: {
    resetUpdateSuccess(state) {
      state.updateSuccess = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchPaymentCards.pending, (state, action) => {
      state.key = action.meta.arg.customerId;
      state.paymentCards = initialState.paymentCards;
      state.isError = false;
    });
    builder.addCase(fetchPaymentCards.fulfilled, (state, action: PayloadAction<PaymentCardsInfo>) => {
      state.paymentCards = action.payload;
    });
    builder.addCase(fetchPaymentCards.rejected, state => {
      state.paymentCards = initialState.paymentCards;
      state.isError = true;
    });

    builder.addCase(updatePaymentCard.pending, (state, action) => {
      state.updating = true;
      state.isError = false;
    });
    builder.addCase(
      updatePaymentCard.fulfilled,
      (state, action: PayloadAction<{ cardId: string; status: PaymentCardStatus }>) => {
        const { cardId, status } = action.payload;
        state.updating = false;
        state.updateSuccess = true;
        if (state.paymentCards.physicalCard?.id === cardId) {
          state.paymentCards = { ...state.paymentCards, physicalCard: { ...state.paymentCards.physicalCard, status } };
        }
        if (state.paymentCards.virtualCard?.id === cardId) {
          state.paymentCards = { ...state.paymentCards, virtualCard: { ...state.paymentCards.virtualCard, status } };
        }
      },
    );
    builder.addCase(updatePaymentCard.rejected, state => {
      state.updating = false;
      state.updateSuccess = false;
      state.isError = true;
    });
  },
});

export const { resetUpdateSuccess } = paymentCardsSlice.actions;
