import TransactionView from '@/components/customer/transaction-history/transaction-details/TransactionView';
import { type TransactionWithSlipId } from '@/components/customer/transaction-history/TransactionHistoryDetailWidget';
import TransactionStatusStamp from '@/components/common/transaction-status-stamp/TransactionStatusStamp';
import { toUtcDateString } from '@/utils/dateTimeUtils';
import { getTransactionAmountLabel, getTransactionFeesLabel, getTransactionTypeLabel } from '@/utils/transactions';
import { type RootState, useAppSelector } from '@/lib/redux';

const Common = ({
  transaction,
  showFees,
}: Readonly<{ transaction: TransactionWithSlipId; showFees?: boolean }>): JSX.Element => {
  const customer = useAppSelector((state: RootState) => state.customer.customer);
  const columnOne = [
    { label: 'transactionType', value: getTransactionTypeLabel(transaction, customer?.customerId), highlight: true },
    { label: 'status', value: <TransactionStatusStamp status={transaction.status} /> },
  ];

  const columnTwo = [
    { label: 'amount', value: getTransactionAmountLabel(transaction, { customerId: customer?.customerId }) },
    ...(showFees
      ? [
          {
            label: 'fees',
            value: getTransactionFeesLabel(transaction, customer?.customerId),
          },
        ]
      : []),
  ];

  const columnThree = [
    { label: 'transactionId', value: transaction.transactionId },
    { label: 'createdDate', value: transaction?.createdDate && toUtcDateString(transaction.createdDate) },
  ];
  return (
    <TransactionView
      columnOne={columnOne}
      columnTwo={columnTwo}
      columnThree={columnThree}
    />
  );
};

export default Common;
