import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import CustomerPiiField from './CustomerPiiField';

import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import type { Account } from '@/types';
import { fetchAccounts } from '@/lib/redux/slices/accounts/actions';
import { reset } from '@/lib/redux/slices/accounts/slice';

export interface BalancesDataProps {
  customerId?: string;
}

export function BalancesData({ customerId }: Readonly<BalancesDataProps>): React.JSX.Element {
  const dispatch = useAppDispatch();
  const { accounts, isError } = useAppSelector((state: RootState) => state.accounts);
  const { t } = useTranslation('customerOverview');

  useEffect(() => {
    if (customerId !== undefined) {
      void dispatch(fetchAccounts(customerId));
    }

    return () => {
      dispatch(reset());
    };
  }, [customerId, dispatch]);

  const renderBalance = (value?: Account): string | undefined => {
    if (value === undefined) {
      return undefined;
    }

    return `${value.currencyCode} ${value.availableBalance}`;
  };

  return (
    <div>
      <CustomerPiiField
        fieldName={t('widgets.customer-pii.balances-data.primary-balance')}
        isError={isError}
      >
        {renderBalance(accounts?.primaryAccount)}
      </CustomerPiiField>
      <CustomerPiiField
        fieldName={t('widgets.customer-pii.balances-data.secondary-balance')}
        isError={isError}
      >
        {renderBalance(accounts?.secondaryAccount)}
      </CustomerPiiField>
    </div>
  );
}
