import { createSlice } from '@reduxjs/toolkit';

import { type LimitLevel } from '@/types/LimitLevel';
import { getLimitLevels } from './actions';
import { type Option } from '@/utils/interfaces';

interface LimitsState {
  limitLevels?: LimitLevel[];
  selectedLevel?: Option | undefined;
  loading: boolean;
  success: boolean;
}

const initialState: LimitsState = {
  limitLevels: undefined,
  selectedLevel: undefined,
  loading: false,
  success: false,
};

export const limits = createSlice({
  name: 'limits',
  initialState,
  reducers: {
    setSelectedLimitLevel(
      state,
      action: {
        payload: Option | undefined;
      },
    ) {
      state.selectedLevel = action.payload;
    },
    reset() {
      return {
        limitLevels: undefined,
        loading: false,
        success: false,
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(getLimitLevels.pending, state => {
      state.loading = true;
    });
    builder.addCase(getLimitLevels.fulfilled, (state, action) => {
      state.limitLevels = action.payload;
      state.loading = false;
      state.success = true;
    });
    builder.addCase(getLimitLevels.rejected, state => {
      state.loading = false;
      state.success = false;
    });
  },
});

export const { reset, setSelectedLimitLevel } = limits.actions;
