import FiltersModal from '@/components/common/filters/FiltersModal';
import InputLabel from '@/components/common/labels/InputLabel';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DebounceInput from '@/components/common/inputs/DebounceInput';
import { type Option } from '@/utils/interfaces';
import { type BusinessAccounts, CountriesEnum, CurrencyEnum, TransactionStatus, TransactionTypeBA } from '@/types';
import styles from '@/components/customer/transaction-history/filters/TransactionHistoryFilters.module.scss';
import DateRangeInput from '@/components/common/inputs/DateRangeInput';
import MultiSelectDropdown from '@/components/common/filters/MultiSelectDropdown';
import Dropdown from '@/components/common/filters/Dropdown';
import i18n from '@/i18n/config';

import countries from '@/i18n/countriesConfig';
import { type RootState, useAppSelector } from '@/lib/redux';
import {
  useTransactionIdFilter,
  usePspTransactionIdFilter,
  useTransactionTypeFilter,
  useTransactionStatusFilter,
  useDateRangeFilter,
  useCountryFilter,
  usePhoneNumberFilter,
  useCustomerIdFilter,
} from '@/components/customer/transaction-history/filters/filterHooks';
import TransactionHistoryFiltersHeader
  from '@/components/customer/transaction-history/filters/TransactionHistoryFiltersHeader';

const ALL_TRANSACTION_BA_TYPES: Option[] = Object.values(TransactionTypeBA).map(type => ({
  text: type,
}));
const ALL_STATUSES: Option[] = Object.values(TransactionStatus).map(type => ({
  text: type,
}));

export default function TransactionHistoryFilters(): React.JSX.Element {
  const { t } = useTranslation(['businessAccounts', 'global']);
  const [showModal, setShowModal] = useState(false);
  const [transactionIdFilter, setTransactionIdFilter] = useTransactionIdFilter();
  const [pspTransactionIdFilter, setPspTransactionIdFilter] = usePspTransactionIdFilter();
  const [transactionTypes, setTransactionTypes] = useTransactionTypeFilter();
  const [transactionStatuses, setTransactionStatuses] = useTransactionStatusFilter();
  const [dates, setDates] = useDateRangeFilter();
  const [country, setCountry] = useCountryFilter();
  const [phoneNumber, setPhoneNumber] = usePhoneNumberFilter();
  const [customerId, setCustomerId] = useCustomerIdFilter();

  const { businessAccounts } = useAppSelector((state: RootState) => state.businessAccounts);

  const dropdownOptions: Option[] = useMemo(() => {
    const options: Option[] = [{ text: t('filters.please-select', { ns: 'global' }), hidden: true, value: undefined }];
    businessAccounts?.forEach((item: BusinessAccounts) => {
      if (item.currencyCode !== CurrencyEnum.USD || item.countryCode === CountriesEnum.ECU) {
        options.push({
          text: countries.getName(item.countryCode, i18n.language) ?? '',
          value: item.countryCode,
        });
      }
    });

    return options;
  }, [t, businessAccounts]);

  const selectedOption = useMemo(
    () => (country ? dropdownOptions.find(item => item.value === country) : dropdownOptions[0]),
    [country, dropdownOptions],
  );

  const onSelect = (option: Option): void => {
    setCountry(option.value ?? '');
  };
  const clearAllFilters = (): void => {
    setTransactionIdFilter('');
    setPspTransactionIdFilter('');
    setTransactionTypes([]);
    setTransactionStatuses([]);
    setDates(['', '']);
    setCountry('');
    setPhoneNumber('');
    setCustomerId('');
  };

  const selectedTransactionTypeOptions = useMemo(
    () =>
      transactionTypes.map(type => ({
        text: type,
      })),
    [transactionTypes],
  );

  const selectedTransactionStatusOptions = useMemo(
    () =>
      transactionStatuses.map(type => ({
        text: type,
      })),
    [transactionStatuses],
  );

  const disabledClearAll = useMemo(
    () =>
      !transactionIdFilter &&
      transactionTypes.length === 0 &&
      transactionStatuses.length === 0 &&
      !dates[0] &&
      !dates[1] &&
      !pspTransactionIdFilter &&
      !country &&
      !phoneNumber &&
      !customerId,
    [transactionIdFilter, transactionStatuses, transactionTypes, dates, pspTransactionIdFilter, country, phoneNumber, customerId],
  );

  const onTypesChange = (options: Option[]): void => setTransactionTypes(options.map(x => x.text));
  const onStatusesChange = (options: Option[]): void => setTransactionStatuses(options.map(x => x.text));

  const maxLength = 24;

  return (
    <>
      <TransactionHistoryFiltersHeader
        disabledClearAll={disabledClearAll}
        onClick={() => setShowModal(true)}
        label={t('filters.button-label', { ns: 'global' })}
      />
      {showModal && (
        <FiltersModal
          onClose={() => setShowModal(false)}
          clearAllFilters={clearAllFilters}
          disabledClearAll={disabledClearAll}
        >
          <InputLabel label={t('filters.time-range-label')}>
            <DateRangeInput
              value={dates}
              setValue={setDates}
            >
              <div className={styles.dateRange}>
                <DateRangeInput.From />
                <DateRangeInput.To />
              </div>
            </DateRangeInput>
          </InputLabel>
          <InputLabel label={t('filters.country-id-label')}>
            <Dropdown
              options={dropdownOptions}
              selectedOption={selectedOption}
              setSelectedOption={option => setCountry(option.value ?? '')}
              onSelect={onSelect}
            />
          </InputLabel>
          <InputLabel label={t('filters.phone-number-label')}>
            <DebounceInput
              value={phoneNumber ?? ''}
              onChange={setPhoneNumber}
            />
          </InputLabel>
          <InputLabel label={t('filters.type-label')}>
            <MultiSelectDropdown
              options={ALL_TRANSACTION_BA_TYPES}
              selectedOptions={selectedTransactionTypeOptions}
              onChange={onTypesChange}
            />
          </InputLabel>
          <InputLabel label={t('filters.status-label')}>
            <MultiSelectDropdown
              options={ALL_STATUSES}
              selectedOptions={selectedTransactionStatusOptions}
              onChange={onStatusesChange}
            />
          </InputLabel>
          <InputLabel label={t('filters.transaction-id-label')}>
            <DebounceInput
              maxLength={maxLength}
              value={transactionIdFilter ?? ''}
              onChange={setTransactionIdFilter}
            />
          </InputLabel>
          <InputLabel label={t('filters.psp-transaction-id-label')}>
            <DebounceInput
              maxLength={maxLength}
              value={pspTransactionIdFilter ?? ''}
              onChange={setPspTransactionIdFilter}
            />
          </InputLabel>
          <InputLabel label={t('filters.customer-id-label')}>
            <DebounceInput
              maxLength={maxLength}
              value={customerId ?? ''}
              onChange={setCustomerId}
            />
          </InputLabel>
        </FiltersModal>
      )}
    </>
  );
}
