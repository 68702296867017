import React, { useMemo } from 'react';
import { toUtcDateString } from '@/utils/dateTimeUtils';
import ClickableRow from '@/components/common/tables/ClickableRow';
import { type Transaction, TransactionType } from '@/types';
import { type ClickOptions } from '@/utils/hooks/useMouseClicks';
import TransactionStatusStamp from '@/components/common/transaction-status-stamp/TransactionStatusStamp';
import { getTransactionAmountLabel, getTransactionTypeLabel } from '@/utils/transactions';
import { parseJSON } from '@/utils/helperFunctions';

type MerchantName = Record<'Header', string>;

export default function TransactionHistoryRow({
  transaction,
  onClick,
  customerId,
}: Readonly<{
  transaction: Transaction;
  onClick: (transactionId: string, options?: ClickOptions) => void;
  customerId: string;
}>): React.JSX.Element {
  const cells = useMemo(() => {
    let merchantName: MerchantName = { Header: '' };

    if (transaction.transactionType === TransactionType.Card && transaction?.metadata) {
      merchantName = parseJSON<MerchantName>(transaction.metadata);
    }

    return [
      { content: toUtcDateString(transaction.createdDate), key: 'createdDate' },
      { content: toUtcDateString(transaction.updatedDate), key: 'updatedDate' },
      { content: transaction.transactionId, key: 'transactionId' },
      { content: getTransactionTypeLabel(transaction, customerId), key: 'transactionType' },
      { content: <TransactionStatusStamp status={transaction.status} />, key: 'status' },
      { content: getTransactionAmountLabel(transaction, { customerId, applyFees: true }), key: 'requestedAmount' },
      { content: transaction.paymentDetail?.paymentMethodName, key: 'paymentMethodName' },
      { content: merchantName?.Header, key: 'merchantName' },
      { content: transaction.statusReason, key: 'statusReason' },
      { content: transaction.slipId, key: 'slipId' },
    ];
  }, [transaction, customerId]);

  return (
    <ClickableRow
      onClick={opt => {
        onClick(transaction?.transactionId ?? '', opt);
      }}
      cells={cells}
    />
  );
}
