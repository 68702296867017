import React from 'react';
import { type ChangeEvent } from 'react';
import styles from './Input.module.scss';
import TransparentCrossIcon from '@/components/icons/TransparentCrossIcon';
import classNames from 'classnames';

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  value: string;
  onChange?: (newValue: string) => void;
  type?: string;
  disabled?: boolean;
  readonly?: boolean;
  focused?: boolean;
  error?: string;
  required?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
}

export default function Input({
  value,
  onChange,
  readonly,
  focused,
  error,
  ...rest
}: Readonly<InputProps>): React.JSX.Element {
  if (readonly !== undefined && readonly) {
    return <span className={`${styles.readonly}`}>{value}</span>;
  }

  const handleClearInput: (event: React.MouseEvent) => void = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onChange?.('');
  };

  const inputStyles = classNames(styles.input, {
    [styles['input-focus']]: focused,
    [styles['has-error']]: !!error,
  });

  return (
    <div className={`${styles.wrapper}`}>
      <input
        className={inputStyles}
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange?.(e.target.value)}
        {...rest}
      />
      {error && <span className={styles.error}>{error}</span>}
      <button
        className={`${styles['close-icon']}`}
        onClick={handleClearInput}
        onMouseDown={event => event.preventDefault()}
      >
        <TransparentCrossIcon />
      </button>
    </div>
  );
}
