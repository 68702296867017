import { fetchPaymentCards } from '@/lib/redux/slices/payment-cards/actions';
import type { PaymentCardsInfo } from '@/types';
import { useEffect } from 'react';
import { type RootState, useAppDispatch } from '@/lib/redux';
import { useSelector } from 'react-redux';
import { type FetchPaymentCardsState } from '@/lib/redux/slices/payment-cards/slice';

export function usePaymentCards(customerId: string): { paymentCards: PaymentCardsInfo; isError: boolean } {
  const paymentCardsState = useSelector<RootState, FetchPaymentCardsState>(state => state.paymentCardsState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (paymentCardsState.key !== customerId) {
      void dispatch(fetchPaymentCards({ customerId }));
    }
  }, [customerId, dispatch, paymentCardsState]);

  return { paymentCards: paymentCardsState.paymentCards, isError: paymentCardsState.isError };
}
