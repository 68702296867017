import { type AddressDocReview } from '@/types';
import { createSlice } from '@reduxjs/toolkit';
import { fetchAddressDocuments } from './actions';

interface AddressDocsState {
  documents: AddressDocReview[];
  page: number;
  count: number;
  itemsPerPage: number;
  isLoading: boolean;
  isError: boolean;
}

const initialState: AddressDocsState = {
  documents: [],
  page: -1,
  count: 0,
  itemsPerPage: -1,
  isLoading: false,
  isError: false,
};

export const addressDocsSlice = createSlice({
  name: 'addressDocsSlice',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAddressDocuments.pending, state => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(fetchAddressDocuments.fulfilled, (state, action) => {
      state.isLoading = false;
      state.documents = action.payload.documents;
      state.count = action.payload.count;
      state.page = action.payload.page;
      state.itemsPerPage = action.payload.itemsPerPage;
    });
    builder.addCase(fetchAddressDocuments.rejected, state => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export const { reset } = addressDocsSlice.actions;
