import { createSlice } from '@reduxjs/toolkit';
import {
  fetchChargebackActionEmptyResult,
  fetchChargebackReversalActionEmptyResult,
  fetchExpiredCardActionEmptyResult,
  fetchMerchantPaymentReversalActionEmptyResult,
  fetchReversalActionEmptyResult,
  fetchCardFeeReversalActionEmptyResult,
  fetchCardFraudRefundActionEmptyResult,
  fetchCardChargebackActionEmptyResult,
} from './actions';

interface TransactionActionsState {
  isReversalEmtpy?: boolean;
  isMerchantReversalEmtpy?: boolean;
  isChargebackEmpty?: boolean;
  isChargebackReversalEmpty?: boolean;
  isCardFeeReversalEmpty?: boolean;
  isCardFraudRefundEmpty?: boolean;
  isExpiredCardEmpty?: boolean;
  isCardChargebackEmpty?: boolean;
}

const initialState: TransactionActionsState = {};

export const transactionActionsSlice = createSlice({
  name: 'transactionActionsSlice',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    // reversal
    builder.addCase(fetchReversalActionEmptyResult.fulfilled, (state, action) => {
      state.isReversalEmtpy = action.payload.isEmpty;
    });
    builder.addCase(fetchReversalActionEmptyResult.rejected, (state, action) => {
      state.isReversalEmtpy = false;
    });
    // merchant payment reversal
    builder.addCase(fetchMerchantPaymentReversalActionEmptyResult.fulfilled, (state, action) => {
      state.isMerchantReversalEmtpy = action.payload.isEmpty;
    });
    builder.addCase(fetchMerchantPaymentReversalActionEmptyResult.rejected, state => {
      state.isMerchantReversalEmtpy = false;
    });
    // chargeback
    builder.addCase(fetchChargebackActionEmptyResult.fulfilled, (state, action) => {
      state.isChargebackEmpty = action.payload.isEmpty;
    });
    builder.addCase(fetchChargebackActionEmptyResult.rejected, state => {
      state.isChargebackEmpty = false;
    });
    // chargeback reversal
    builder.addCase(fetchChargebackReversalActionEmptyResult.fulfilled, (state, action) => {
      state.isChargebackReversalEmpty = action.payload.isEmpty;
    });
    builder.addCase(fetchChargebackReversalActionEmptyResult.rejected, state => {
      state.isChargebackReversalEmpty = false;
    });
    // expired card
    builder.addCase(fetchExpiredCardActionEmptyResult.fulfilled, (state, action) => {
      state.isExpiredCardEmpty = action.payload.isEmpty;
    });
    builder.addCase(fetchExpiredCardActionEmptyResult.rejected, state => {
      state.isExpiredCardEmpty = false;
    });
    // card fee reversal
    builder.addCase(fetchCardFeeReversalActionEmptyResult.fulfilled, (state, action) => {
      state.isCardFeeReversalEmpty = action.payload.isEmpty;
    });
    builder.addCase(fetchCardFeeReversalActionEmptyResult.rejected, state => {
      state.isCardFeeReversalEmpty = false;
    });
    // fraud refund
    builder.addCase(fetchCardFraudRefundActionEmptyResult.fulfilled, (state, action) => {
      state.isCardFraudRefundEmpty = action.payload.isEmpty;
    });
    builder.addCase(fetchCardFraudRefundActionEmptyResult.rejected, state => {
      state.isCardFraudRefundEmpty = false;
    });
    // card chargeback
    builder.addCase(fetchCardChargebackActionEmptyResult.fulfilled, (state, action) => {
      state.isCardChargebackEmpty = action.payload.isEmpty;
    });
    builder.addCase(fetchCardChargebackActionEmptyResult.rejected, state => {
      state.isCardChargebackEmpty = false;
    });
  },
});

export const { reset } = transactionActionsSlice.actions;
