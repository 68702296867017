import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import CustomerPiiField from './CustomerPiiField';
import { StyledHeader } from '../CustomerPii';

import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import { fetchDeviceInfo } from '@/lib/redux/slices/device-info/actions';
import { reset } from '@/lib/redux/slices/device-info/slice';

export interface DeviceInfoProps {
  customerPhoneNumber?: string;
}

export function DeviceInfo({ customerPhoneNumber }: Readonly<DeviceInfoProps>): React.JSX.Element {
  const dispatch = useAppDispatch();
  const { deviceInfo, isError } = useAppSelector((state: RootState) => state.deviceInfo);
  const { t } = useTranslation('customerOverview');

  useEffect(() => {
    if (customerPhoneNumber !== undefined) {
      void dispatch(fetchDeviceInfo(customerPhoneNumber));
    }

    return () => {
      dispatch(reset());
    };
  }, [customerPhoneNumber, dispatch]);

  const noDevices = deviceInfo?.riskScore.riskData.trustedDevices.length === 0;

  return (
    <div>
      <StyledHeader>{t('widgets.customer-pii.device-info.header-title')}</StyledHeader>
      <StyledHeader>{t('widgets.customer-pii.device-info.header-last-login')}</StyledHeader>
      <CustomerPiiField
        fieldName={t('widgets.customer-pii.device-info.device-id')}
        isError={isError}
      >
        {deviceInfo?.deviceId}
      </CustomerPiiField>
      <CustomerPiiField
        fieldName={t('widgets.customer-pii.device-info.brand')}
        isError={isError}
      >
        {deviceInfo?.riskScore.riskData.brand}
      </CustomerPiiField>
      <CustomerPiiField
        fieldName={t('widgets.customer-pii.device-info.model')}
        isError={isError}
      >
        {deviceInfo?.riskScore.riskData.model}
      </CustomerPiiField>
      <CustomerPiiField
        fieldName={t('widgets.customer-pii.device-info.os')}
        isError={isError}
      >
        {deviceInfo?.riskScore.riskData.os}
      </CustomerPiiField>
      <CustomerPiiField
        fieldName={t('widgets.customer-pii.device-info.os-version')}
        isError={isError}
      >
        {deviceInfo?.riskScore.riskData.osVersion}
      </CustomerPiiField>
      <CustomerPiiField
        fieldName={t('widgets.customer-pii.device-info.ip-address')}
        isError={isError}
      >
        {deviceInfo?.riskScore.riskData.ipAddress}
      </CustomerPiiField>
      <CustomerPiiField
        fieldName={t('widgets.customer-pii.device-info.ip-country')}
        isError={isError}
      >
        {deviceInfo?.riskScore.riskData.ipCountry}
      </CustomerPiiField>
      <CustomerPiiField
        fieldName={t('widgets.customer-pii.device-info.app-version')}
        isError={isError}
      >
        {deviceInfo?.riskScore.riskData.agentAppInfo}
      </CustomerPiiField>
      <StyledHeader>
        {t('widgets.customer-pii.device-info.used-devices')} {noDevices ? 'N/A' : ' '}
      </StyledHeader>
      {!noDevices && (
        <CustomerPiiField isError={isError}>
          {deviceInfo?.riskScore.riskData.trustedDevices.map(ipAddresses => <div key={ipAddresses}>{ipAddresses}</div>)}
        </CustomerPiiField>
      )}
    </div>
  );
}
