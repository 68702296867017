import authAxios from '@/auth/axios';
import { useCallback, useState, useMemo } from 'react';
import { useAppDispatch } from '@/lib/redux';

import { updateFeatureFlag } from '@/lib/redux/slices/feature-flags/actions';
import { type FeatureFlag } from '@/types/FeatureFlag';
import { errorToast, successToast } from '@/utils/toastMessage';

export const useFeatureFlagUpdate = (
  updateUrl: string,
  updateData: { flagId: string } | FeatureFlag,
): { isLoading: boolean; error?: unknown; editFeatureFlag: (flag: FeatureFlag) => void } => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>(null);

  const dispatch = useAppDispatch();

  const editFeatureFlag = useCallback(
    async (flag: FeatureFlag) => {
      setIsLoading(true);
      try {
        const response = await authAxios.put<{ data: FeatureFlag }>(updateUrl, updateData);
        if (updateUrl.includes('activate')) {
          dispatch(updateFeatureFlag({ ...flag, archived: false }));
        } else if (updateUrl.includes('archive')) {
          dispatch(updateFeatureFlag({ ...flag, archived: true }));
        } else {
          dispatch(updateFeatureFlag(response.data.data));
        }
        successToast();
      } catch (error) {
        errorToast();
        setError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, updateUrl, updateData],
  );

  const result = useMemo(
    () => ({
      isLoading,
      error,
      editFeatureFlag,
    }),
    [isLoading, error, editFeatureFlag],
  );

  return result;
};
