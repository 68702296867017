import { TransactionType } from '@/types';
import {
  errorKeyPspTrnNotFound,
  errorKeySlipNotFound,
  errorKeyTrnNotFound,
  errorKeyUserNotFound,
} from '@/utils/errorMessages';
import { type SearchFeaturesSettings } from '@/utils/interfaces';

export const SEARCH_SETTINGS: SearchFeaturesSettings = {
  phoneNumber: {
    validationRegex: /^[1-9]\d{9,12}$/,
    validationErrorMessage: 'search-component.invalid-phone-number-message',
    navigationUrl: '/customer/{customerId}',
    serviceUrl: '/customers/customers-info',
    pathsInResponse: { customerId: ['data.result.0.customerId'] },
    queryParams: {
      PhoneNumber: '{searchValue}',
    },
    notFoundErrorMessage: errorKeyUserNotFound,
    sanitizeSearchValue: (value: string) => value.replaceAll(' ', ''),
  },
  email: {
    validationRegex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    validationErrorMessage: 'search-component.invalid-email-message',
    navigationUrl: '/customer/{customerId}',
    serviceUrl: '/customers/customers-info',
    pathsInResponse: { customerId: ['data.result.0.customerId'] },
    queryParams: {
      Email: '{searchValue}',
    },
    notFoundErrorMessage: errorKeyUserNotFound,
  },
  customerId: {
    validationRegex: /^[a-fA-F0-9]{24}$/,
    validationErrorMessage: 'search-component.invalid-customer-id-message',
    navigationUrl: '/customer/{customerId}',
    serviceUrl: '/customers/customers-info',
    pathsInResponse: { customerId: ['data.result.0.customerId'] },
    queryParams: {
      CustomerId: '{searchValue}',
    },
    notFoundErrorMessage: errorKeyUserNotFound,
  },
  kuadyTrnId: {
    validationRegex: /^[a-fA-F0-9]{24}$/,
    validationErrorMessage: 'search-component.invalid-transaction-message',
    navigationUrl: '/customer/{customerId}/transaction-history?transactionId={transactionId}',
    serviceUrl: 'transactions/{searchValue}',
    pathsInResponse: {
      customerId: ['data.source.customerId', 'data.destination.customerId'],
      transactionId: ['data.transactionId'],
    },
    notFoundErrorMessage: errorKeyTrnNotFound,
    extractParamsFromResponse: (response, pathsInResponse, fallback) => {
      if (
        response?.data?.transactionId &&
        [TransactionType.MerchantPaymentReversal, TransactionType.PaymentToCustomer].includes(
          response?.data?.transactionType as TransactionType,
        ) &&
        response.data.destination?.customerId
      ) {
        return { customerId: response.data.destination.customerId, transactionId: response.data.transactionId };
      }
      return fallback(response, pathsInResponse);
    },
  },
  slipId: {
    validationRegex: /^[a-fA-F0-9]{24}$/,
    validationErrorMessage: 'search-component.invalid-slip-message',
    navigationUrl: '/customer/{customerId}/transaction-history?slipId={slipId}',
    serviceUrl: 'transactions/{searchValue}',
    pathsInResponse: {
      customerId: ['data.source.customerId', 'data.destination.customerId'],
      slipId: ['data.slipId'],
    },
    notFoundErrorMessage: errorKeySlipNotFound,
  },
  pspTransactionId: {
    validationRegex: /^.*$/,
    validationErrorMessage: 'search-component.invalid-psp-transaction-message',
    navigationUrl: '/customer/{customerId}/transaction-history?pspTransactionId={searchValue}',
    serviceUrl: 'transactions/search',
    body: {
      pspTransactions: ['searchValue'],
    },
    pathsInResponse: {
      customerId: ['data.0.source.customerId', 'data.0.destination.customerId'],
      transactionId: ['data.0.transactionId'],
    },
    notFoundErrorMessage: errorKeyPspTrnNotFound,
  },
};
