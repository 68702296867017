import { type TransactionWithSlipId } from '@/components/customer/transaction-history/TransactionHistoryDetailWidget';
import Common from './Common';

const Cards = ({ transaction }: { transaction: TransactionWithSlipId }): JSX.Element => {
  return (
    <Common
      transaction={transaction}
      showFees
    />
  );
};

export default Cards;
